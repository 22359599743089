import { searchParams } from "app/helpers/commonInterface";
import { axiosInstance } from "../axiosInstance";
import { getSearchQueryString } from "app/helpers/helperFunction";
import {
  GET_ALL_REIMBURSEMENT_INVOICE_API_ENDPOINT,
  REIMBURSEMENT_INVOICE_API_ENDPOINT,
} from "app/helpers/config";

export const addReimbursementInvoiceService = async (data: any) => {
  return await axiosInstance.post(REIMBURSEMENT_INVOICE_API_ENDPOINT, data);
};

export const getReimbursementInvoiceListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(
    `${GET_ALL_REIMBURSEMENT_INVOICE_API_ENDPOINT}?${queryString}`
  );
};

export const getReimbursementInvoiceService = (invoiceId: number) => {
  return axiosInstance.get(`${REIMBURSEMENT_INVOICE_API_ENDPOINT}?id=${invoiceId}`);
};

export const updateReimbursementInvoiceService = async (data: any) => {
  return await axiosInstance.put(REIMBURSEMENT_INVOICE_API_ENDPOINT, data);
};

// export const convertFinalProformaInvoiceService = async (billId: number) => {
//   return await axiosInstance.get(
//     `${FINAL_PROFORMA_INVOCE_API_ENDPOINT}?id=${billId}`
//   );
// };
