import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "app/helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import { searchParams } from "app/helpers/commonInterface";
import {
  addReimbursementInvoiceService,
  getReimbursementInvoiceListService,
  getReimbursementInvoiceService,
  updateReimbursementInvoiceService,
} from "./reimbursementService";
import {
  ADD_REIMBURSEMENT_INVOICE_FAILURE,
  ADD_REIMBURSEMENT_INVOICE_REQUEST,
  ADD_REIMBURSEMENT_INVOICE_SUCCESS,
  GET_REIMBURSEMENT_INVOICE_FAILURE,
  GET_REIMBURSEMENT_INVOICE_LIST_FAILURE,
  GET_REIMBURSEMENT_INVOICE_LIST_REQUEST,
  GET_REIMBURSEMENT_INVOICE_LIST_SUCCESS,
  GET_REIMBURSEMENT_INVOICE_REQUEST,
  GET_REIMBURSEMENT_INVOICE_SUCCESS,
  UPDATE_REIMBURSEMENT_INVOICE_FAILURE,
  UPDATE_REIMBURSEMENT_INVOICE_REQUEST,
  UPDATE_REIMBURSEMENT_INVOICE_SUCCESS,
} from "../actionTypes";
import { GetErrorMessage } from "../stateHelperFunction";

export const addReimbursementInvoice = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_REIMBURSEMENT_INVOICE_REQUEST));

    await addReimbursementInvoiceService(data).then(
      (result: any) => {
        // dispatch(success(ADD_BILL_SUCCESS, result.data))
        dispatch(success(ADD_REIMBURSEMENT_INVOICE_SUCCESS, result));
        showToastMessageSuccess("Reimbursement invoice added");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            ADD_REIMBURSEMENT_INVOICE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getReimbursementInvoiceList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_REIMBURSEMENT_INVOICE_LIST_REQUEST));

    getReimbursementInvoiceListService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_REIMBURSEMENT_INVOICE_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_REIMBURSEMENT_INVOICE_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getReimbursementInvoice = (invoiceId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_REIMBURSEMENT_INVOICE_REQUEST));

    getReimbursementInvoiceService(invoiceId).then(
      (result: any) =>
        dispatch(success(GET_REIMBURSEMENT_INVOICE_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_REIMBURSEMENT_INVOICE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const updateReimbursementInvoice = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_REIMBURSEMENT_INVOICE_REQUEST));

    await updateReimbursementInvoiceService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_REIMBURSEMENT_INVOICE_SUCCESS, result.data));
        showToastMessageSuccess("Reimbursement invoice updated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_REIMBURSEMENT_INVOICE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

// export const convertToFinalProformaInvoice = (
//   billId: number,
//   successCallback: Function
// ) => {
//   return async (dispatch: any) => {
//     dispatch(request(FINAL_REIMBURSEMENT_INVOICE_REQUEST));

//     await convertFinalProformaInvoiceService(billId).then(
//       (result: any) => {
//         dispatch(success(FINAL_REIMBURSEMENT_INVOICE_SUCCESS, result.data));
//         showToastMessageSuccess("Final invoice generated");
//         successCallback();
//       },
//       (error: any) => {
//         dispatch(
//           failure(
//             FINAL_REIMBURSEMENT_INVOICE_FAILURE,
//             GetErrorMessage(error?.response?.data)
//           )
//         );
//         showToastMessageFailure(GetErrorMessage(error?.response?.data));
//       }
//     );
//   };
// };
