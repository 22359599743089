import { connect } from "react-redux";
import { FC, Fragment, useState } from "react";
import {
  Field,
  FieldArray,
  FormikHelpers,
  FormikState,
  FormikValues,
  useFormikContext,
} from "formik";

import { addFileObject } from "app/helpers/commonInterface";
import CustomDatePicker from "../../../commonComponent/datePicker/CustomDatePicker";
import DropDown from "../../../commonComponent/dropDown/DropDown";
import ImageDisplay from "../../../commonComponent/ImageDisplay";
import { getFormData, getImage } from "../../../../helpers/helperFunction";
import { generalFileUpload } from "../../../../reducers/task/taskAction";
import { subKey, workCategoryIdDataObject } from "./CustomDropdown";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  taskArr: Array<workCategoryIdDataObject>;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  uploadFileToRemarksDispatch: Function;
}

const SelectedTaskList: FC<props> = ({
  uploadFileToRemarksDispatch,
  taskArr,
  masterDataList,
}) => {
  const [loading, setLoading] = useState({
    loading: false,
    index: -1,
    index2: -1,
  });
  const {
    values,
    setFieldValue,
    isSubmitting,
  }: FormikState<FormikValues> & FormikHelpers<FormikValues> =
    useFormikContext();

  const onChangeFileHandler = (
    file: any,
    setFieldValue: any,
    name: string,
    index: number,
    index2: number
  ) => {
    setLoading({ loading: false, index: index, index2: index2 });
    uploadFileToRemarksDispatch(
      getFormData({ file: file }),
      (fileObj: addFileObject) => {
        setLoading({ loading: false, index: -1, index2: -1 });
        setFieldValue(name, {
          ...values.workCategoryId[index].subKey[index2].remarks[0],
          filePath: fileObj.FilePath,
          fileName: fileObj.FileName,
          fileUrl: fileObj.FileUrl,
          mimeType: fileObj.MimeType,
        });
      }
    );
  };

  const deleteSelectedFile = (
    name: string,
    index: number,
    index2: number,
    setFieldValue: Function
  ) => {
    const remarkData =
      values.workCategoryId[index].subKey[index2].remarks[0]?.remark;
    setFieldValue(name, remarkData ? { remark: remarkData } : { remark: "" });
  };

  return (
    <>
      {taskArr?.length ? (
        <div className="fs-3 fw-semibold mb-n2">Task Details</div>
      ) : (
        ""
      )}
      <div className="mt-8">
        <FieldArray
          name="workCategoryId"
          render={(arrayHelpers) => {
            return (
              <>
                {taskArr.map(
                  (task: workCategoryIdDataObject, index: number) => {
                    return (
                      <Fragment key={index}>
                        <FieldArray
                          name={`workCategoryId[${index}].subKey`}
                          render={(arrayHelpers) => {
                            return (
                              <div className="">
                                {task.subKey.map(
                                  (subTask: subKey, index2: number) => {
                                    console.log(subTask, "SUBTASK");
                                    return (
                                      <div
                                        key={index2}
                                        className="border border-2 rounded-2 row px-4 mb-4"
                                      >
                                        <div
                                          className="p-0 p-lg-3 align-items-center d-flex fs-4 fw-semibold min-h-50px"
                                          role="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#kt_account_signin_method-${index}-${index2}`}
                                        >
                                          {subTask.typeName}
                                        </div>
                                        <div
                                          id={`kt_account_signin_method-${index}-${index2}`}
                                          className="border-top collapse pt-4 px-lg-3 px-0 show"
                                        >
                                          <div className="row mb-6">
                                            <div className="col-sm-6 col-lg-4">
                                              <label className="col-form-label fw-semibold fs-6">
                                                Article
                                              </label>

                                              <div className="">
                                                <DropDown
                                                  placeholder="Select Article"
                                                  className="text-start form-control form-control-lg form-control-solid form-select"
                                                  options={
                                                    masterDataList.masterData
                                                      ?.data?.records?.Admin &&
                                                    masterDataList.masterData
                                                      ?.data?.records
                                                      ?.Article &&
                                                    masterDataList.masterData
                                                      ?.data?.records
                                                      ?.TeamLeader
                                                      ? [
                                                        {
                                                          value: "",
                                                          name: "Select Article",
                                                        },
                                                          ...masterDataList.masterData?.data?.records?.Admin?.map(
                                                            (assignee: any) => {
                                                              return {
                                                                value:
                                                                  assignee.Id,
                                                                name: `${assignee.FirstName} ${assignee.LastName}`,
                                                              };
                                                            }
                                                          ),
                                                          ...masterDataList.masterData?.data?.records?.Article?.map(
                                                            (assignee: any) => {
                                                              return {
                                                                value:
                                                                  assignee.Id,
                                                                name: `${assignee.FirstName} ${assignee.LastName}`,
                                                              };
                                                            }
                                                          ),
                                                          ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                                            (assignee: any) => {
                                                              return {
                                                                value:
                                                                  assignee.Id,
                                                                name: `${assignee.FirstName} ${assignee.LastName}`,
                                                              };
                                                            }
                                                          ),
                                                        ]
                                                      : []
                                                  }
                                                  setFieldValue={setFieldValue}
                                                  name={`workCategoryId[${index}].subKey[${index2}].assigneeId`}
                                                  defaultValue={{
                                                    value: subTask.assigneeId,
                                                    name: subTask.assigneeName,
                                                  }}
                                                  disabled={isSubmitting}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-4">
                                              <label className="col-form-label fw-semibold fs-6">
                                                Manager
                                              </label>

                                              <div className="">
                                                <DropDown
                                                  placeholder="Select Manager"
                                                  className="text-start form-control form-control-lg form-control-solid form-select"
                                                  options={
                                                    masterDataList.masterData
                                                      ?.data?.records?.Admin &&
                                                    masterDataList.masterData
                                                      ?.data?.records
                                                      ?.Article &&
                                                    masterDataList.masterData
                                                      ?.data?.records
                                                      ?.TeamLeader
                                                      ? [
                                                        {
                                                          value: "",
                                                          name: "Select Manager",
                                                        },
                                                          ...masterDataList.masterData?.data?.records?.Admin?.map(
                                                            (assignee: any) => {
                                                              return {
                                                                value:
                                                                  assignee.Id,
                                                                name: `${assignee.FirstName} ${assignee.LastName}`,
                                                              };
                                                            }
                                                          ),
                                                          ...masterDataList.masterData?.data?.records?.Article?.map(
                                                            (assignee: any) => {
                                                              return {
                                                                value:
                                                                  assignee.Id,
                                                                name: `${assignee.FirstName} ${assignee.LastName}`,
                                                              };
                                                            }
                                                          ),
                                                          ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                                            (assignee: any) => {
                                                              return {
                                                                value:
                                                                  assignee.Id,
                                                                name: `${assignee.FirstName} ${assignee.LastName}`,
                                                              };
                                                            }
                                                          ),
                                                        ]
                                                      : []
                                                  }
                                                  setFieldValue={setFieldValue}
                                                  name={`workCategoryId[${index}].subKey[${index2}].managerId`}
                                                  defaultValue={{
                                                    value: subTask.managerId,
                                                    name: subTask.managerName,
                                                  }}
                                                  disabled={isSubmitting}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-4">
                                              <label className="col-form-label fw-semibold fs-6">
                                                Expert
                                              </label>
                                              <div>
                                                <DropDown
                                                  placeholder="Select Expert"
                                                  className="text-start form-control form-control-lg form-control-solid form-select"
                                                  options={
                                                    masterDataList.masterData
                                                      ?.data?.records?.Admin &&
                                                    masterDataList.masterData
                                                      ?.data?.records
                                                      ?.Article &&
                                                    masterDataList.masterData
                                                      ?.data?.records
                                                      ?.TeamLeader
                                                      ? [
                                                        {
                                                          value: "",
                                                          name: "Select Expert",
                                                        },
                                                          ...masterDataList.masterData?.data?.records?.Admin?.map(
                                                            (assignee: any) => {
                                                              return {
                                                                value:
                                                                  assignee.Id,
                                                                name: `${assignee.FirstName} ${assignee.LastName}`,
                                                              };
                                                            }
                                                          ),
                                                          ...masterDataList.masterData?.data?.records?.Article?.map(
                                                            (assignee: any) => {
                                                              return {
                                                                value:
                                                                  assignee.Id,
                                                                name: `${assignee.FirstName} ${assignee.LastName}`,
                                                              };
                                                            }
                                                          ),
                                                          ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                                            (assignee: any) => {
                                                              return {
                                                                value:
                                                                  assignee.Id,
                                                                name: `${assignee.FirstName} ${assignee.LastName}`,
                                                              };
                                                            }
                                                          ),
                                                        ]
                                                      : []
                                                  }
                                                  setFieldValue={setFieldValue}
                                                  name={`workCategoryId[${index}].subKey[${index2}].expertId`}
                                                  defaultValue={{
                                                    value: subTask.expertId,
                                                    name: subTask.expertName,
                                                  }}
                                                  disabled={isSubmitting}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            {!values.workCategoryId[index]
                                              .subKey[index2].isRepeatative && (
                                              <div className="col-sm-6 col-lg-4">
                                                <label className="col-form-label fw-semibold fs-6">
                                                  Ideal Days
                                                </label>

                                                <div className="">
                                                  <Field
                                                    name={`workCategoryId[${index}].subKey[${index2}].idealDays`}
                                                    id={`workCategoryId[${index}].subKey[${index2}].idealDays`}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder="Ideal Days"
                                                    disabled={isSubmitting}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                            {values.workCategoryId[index]
                                              .subKey[index2].isRepeatative && (
                                              <div className="col-sm-6 col-lg-4">
                                                <label className="col-form-label fw-semibold fs-6">
                                                  Government Fix Date
                                                </label>
                                                <div className="">
                                                  <CustomDatePicker
                                                    name={`workCategoryId[${index}].subKey[${index2}].fixedDate`}
                                                    currentValue={
                                                      values.workCategoryId[
                                                        index
                                                      ].subKey[index2].fixedDate
                                                        ? new Date(
                                                            values.workCategoryId[
                                                              index
                                                            ].subKey[
                                                              index2
                                                            ].fixedDate
                                                          )
                                                        : null
                                                    }
                                                    placeholder="Government Fix Date"
                                                    setFieldValue={
                                                      setFieldValue
                                                    }
                                                    disabled={isSubmitting}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                            <div className="col-sm-6 col-lg-4">
                                              <label className="col-form-label fw-semibold fs-6">
                                                Task OnBoard Days
                                              </label>

                                              <div className="">
                                                <Field
                                                  name={`workCategoryId[${index}].subKey[${index2}].onboardBefore`}
                                                  id={`workCategoryId[${index}].subKey[${index2}].onboardBefore`}
                                                  className="form-control form-control-lg form-control-solid"
                                                  placeholder="Task OnBoard Days"
                                                  disabled={isSubmitting}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <div className="col-sm-6 col-lg-4">
                                              <label className="col-form-label fw-semibold fs-6">
                                                Start Date
                                              </label>

                                              <div className="">
                                                <CustomDatePicker
                                                  name={`workCategoryId[${index}].subKey[${index2}].startDate`}
                                                  placeholder="Start Date"
                                                  setFieldValue={setFieldValue}
                                                  disabled={isSubmitting}
                                                />
                                              </div>
                                            </div>
                                            {/* </div> */}
                                            {/* <div className="row mb-6"> */}
                                            <div className="col-xl-8">
                                              <label className="col-form-label fw-semibold fs-6">
                                                Remark
                                              </label>
                                              <div className="d-flex gap-3 flex-row flex-wrap flex-sm-nowrap align-items-start">
                                                <div className="col-lg-6 col-sm-6 h-sm-100">
                                                  <textarea
                                                    placeholder="Remark"
                                                    className="form-control form-control-lg form-control-solid vertical-scroll h-sm-100"
                                                    name={`workCategoryId[${index}].subKey[${index2}].remarks[0].remark`}
                                                    onChange={(e: any) =>
                                                      setFieldValue(
                                                        `workCategoryId[${index}].subKey[${index2}].remarks[0].remark`,
                                                        e.target.value
                                                      )
                                                    }
                                                    disabled={isSubmitting}
                                                  ></textarea>
                                                </div>
                                                <div className="col-9 col-sm-5 col-lg-6 col-xxl-4">
                                                  {!values.workCategoryId[index]
                                                    .subKey[index2].remarks[0]
                                                    ?.filePath && (
                                                    <label
                                                      htmlFor={`remark-file-${index}-${index2}`}
                                                      className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 justify-content-center align-items-center gap-3 cursor-pointer"
                                                    >
                                                      <div>
                                                        {loading.loading &&
                                                        loading.index ==
                                                          index &&
                                                        loading.index2 ==
                                                          index2 ? (
                                                          <SmallLoader />
                                                        ) : (
                                                          <i className="fs-6 text-primary fw-semibold bi bi-upload me-1"></i>
                                                        )}
                                                      </div>
                                                      <div className="fs-5 text-primary fw-semibold">
                                                        Quick file uploader
                                                      </div>
                                                      <Field
                                                        type="file"
                                                        value={undefined}
                                                        className="d-none"
                                                        id={`remark-file-${index}-${index2}`}
                                                        name={`workCategoryId[${index}].subKey[${index2}].remarks[0].FileName`}
                                                        onChange={(e: any) =>
                                                          onChangeFileHandler(
                                                            e.target.files[0],
                                                            setFieldValue,
                                                            `workCategoryId[${index}].subKey[${index2}].remarks[0]`,
                                                            index,
                                                            index2
                                                          )
                                                        }
                                                        disabled={isSubmitting}
                                                        accept=".xlsx, .xls,image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf, .jpg, .jpeg, .png"
                                                      />
                                                    </label>
                                                  )}
                                                  {values.workCategoryId[index]
                                                    .subKey[index2].remarks[0]
                                                    ?.filePath && (
                                                    <div className="align-items-center d-flex h-100 gap-2 justify-content-start px-4 py-4 shadow-sm rounded-2">
                                                      
                                                      <ImageDisplay
                                                        path={getImage(
                                                          values.workCategoryId[
                                                            index
                                                          ].subKey[index2]
                                                            .remarks[0].mimeType
                                                        )}
                                                        errorPath="/media/task/doc-1.png"
                                                        className="w-30px h-30px"
                                                        altText="documentType"
                                                      />
                                                      <div className="fs-5 fw-bold upload-file-name">
                                                        <span className="">
                                                          {
                                                            values
                                                              .workCategoryId[
                                                              index
                                                            ].subKey[index2]
                                                              .remarks[0]
                                                              .fileName
                                                          }
                                                        </span>
                                                      </div>
                                                      <button
                                                        className="btn p-2"
                                                        onClick={() =>
                                                          deleteSelectedFile(
                                                            `workCategoryId[${index}].subKey[${index2}].remarks[0]`,
                                                            index,
                                                            index2,
                                                            setFieldValue
                                                          )
                                                        }
                                                        disabled={isSubmitting}
                                                      >
                                                        <i className="text-hover-primary fa fa-solid fa-trash fs-6"></i>
                                                      </button>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            );
                          }}
                        />
                      </Fragment>
                    );
                  }
                )}
              </>
            );
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    uploadFileToRemarksDispatch: (file: any, successCallback: Function) =>
      dispatch(generalFileUpload(file, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedTaskList);