// user constants
export const ASSIGNEE = 1;

export const EXPERT = 2;

export const MANAGER = 3;

export const CREATOR = 4;

// task status constants
export const UNASSIGNED = { id: 1, name: "Unassigned" };

export const ASSIGNED = { id: 2, name: "Assigned" };

export const SENT_FOR_CHECK = { id: 3, name: "Sent for Check" };

export const SENT_FOR_CONFIRMATION = { id: 4, name: "Sent for Confirmation" };

export const COMPLETED = { id: 5, name: "Completed" };

export const APPROVERD = { id: 6, name: "Approved" };

export const ON_HOLD = { id: 7, name: "On Hold" };

export const PAUSED = { id: 8, name: "Paused" };

export const REJECTED = { id: 9, name: "Rejected" };

// enum as {
//     a= "l",
//     b="dsc"
// }

//for urgent task
export const URGENT = {id:1,name:"Urgent"};
export const NOTURGENT = {id:2,name:"Not Urgent"}

export const GET_TASK_ASSIGNED = "assigned";
export const GET_TASK_CHECKING = "checking";
export const GET_TASK_FOLLOW_UP = "followup";

export enum DOCUMENT_TYPE_REPEATATION {
  Monthly = 1,
  Quarterly = 2,
  HalfYearly = 3,
  Yearly = 4,
}

export enum TRANSACTION_TYPE {
  Advance = 1,
  "Against Bill" = 2,
  "GovernmentFess/Tax" = 3,
  Other = 4,
  Reimbursement = 5,
  Expense = 6,
}

export enum PAYMENT_STATUS {
  "Billing Pending" = 1,
  "Payment Pending" = 2,
  "Partially Paid" = 3,
  "Fully Paid" = 4,
  "Free Of Cost" = 5,
  "Proforma Generated" = 6,
  "Reimbursement Generated" = 7,
}

export enum REIMBURSEMENT_PAYMENT_STATUS {
  
  "Reimbursement Generated" = 1,
  "Reimbursement Updated" = 2,
}

export enum ACTIVITY_STATUS {
  Unassigned = 1,
  Assigned = 2,
  Completed = 3,
  Approved = 4,
  OnHold = 5,
  Paused = 6,
  }

export const PHONE_NUMBER_REGX =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  export const STATES = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry"
  ];
  