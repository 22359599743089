import { connect } from "react-redux";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Avatar from "app/components/commonComponent/Avatar";
import BillingForm from "app/components/pages/billing/BillingForm";
import ClientMessagePopup from "app/components/commonComponent/clientMessagePopup/ClientMessagePopup";
import { clientMessage } from "app/components/pages/task/component/TaskGrouping";
import { convert, getColor, getStatus } from "app/helpers/helperFunction";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import hasPermission, {
  actionsRole,
} from "app/components/pages/auth/core/permission";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import TaskChat from "../chat/TaskChat";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { taskData } from "app/reducers/task/taskDataInterface";
import hasPaymentAccess from "app/components/pages/auth/core/paymentAccess";

interface props {
  taskDetails: taskData;
  updateTaskAssigneeDispatch: Function;
  setOtpPopup: Function;
  dropDownValue: { value: string; name: string };
  setDropDownValue: Function;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  openChatId:any
}

const TaskCompletionDetails: FC<props> = ({
  masterDataList,
  setDropDownValue,
  dropDownValue,
  updateTaskAssigneeDispatch,
  setOtpPopup,
  taskDetails,
  openChatId
}) => {
  const params = useParams();
  const [clientMessage, setClientMessage] = useState<clientMessage>({
    show: false,
  });
  const [openChat, setOpenChat] = useState(false);
  const [bill, setBill] = useState({ show: false, edit: false, billId: null, isReimbursement: false });
  const [loading, setLoading] = useState(false);
  const [chatId, setChatId] = useState(0);

  useEffect(()=>{
    console.log(openChatId)
     if(openChatId && openChatId.openChat != 0 && openChatId.openChat == Number(params.id)){
      setChatId(openChatId.openChat)
      setOpenChat(true)
     }
  },[openChatId])

  const closeModal = () => {
    setBill({ show: false, edit: false, billId: null, isReimbursement: false });
  };

  const clientMessagePopupModal = () => {
    setClientMessage({ show: false });
  };

  const onChangeAssigneeHandler = async (e: any) => {
    if (hasPermission(actionsRole.ADMIN)) {
      setLoading(true);
      await updateTaskAssigneeDispatch(
        {
          taskId: [Number(params.id)],
          assigneeId: e.target.dataset.id,
          otp: "",
        },
        () => {
          setDropDownValue({
            value: e.target.dataset.id,
            name: e.target.textContent,
          });
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    } else {
      setOtpPopup({
        show: true,
        apiData: e.target.dataset.id,
        article: e.target.textContent,
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-column flex-sm-row flex-lg-column flex-xl-row gap-3 mb-3">
        <div>
          <div>
            <div className="w-100">
              <div className="row fs-6 align-items-center fw-semibold mb-4 d-none">
                <div className="col-5 col-lg-5 col-sm-3 col-xxl-3 text-gray-600 ">
                  TaskCode
                </div>
                <div className="rounded-2 col-6 px-0">
                  {taskDetails.TaskCode || "-"}
                </div>
              </div>
              <div className="row fs-6 align-items-center fw-semibold mb-4">
                <div className="col-5 col-lg-5 col-sm-3 col-xxl-3 text-gray-600 ">
                  Due Date
                </div>
                <div className="rounded-2 col-6 px-0">
                  {taskDetails.DueDate ? convert(taskDetails.DueDate) : "-"}
                </div>
              </div>
              <div className="row fs-6 align-items-center mb-4 fw-semibold">
                <div className="col-5 col-lg-5 col-sm-3 col-xxl-3 text-gray-600 ">
                  Status
                </div>
                <div
                  className={`col-6 w-auto badge status-${taskDetails.StatusValue}`}
                >
                  {getStatus(taskDetails.StatusValue)?.name}
                </div>
              </div>
              <div className="row fs-6 align-items-center w-100 mb-4 fw-semibold">
                <div className="col-5 col-lg-5 col-sm-3 col-xxl-3 text-gray-600 ">
                  Article
                </div>
                <div className="col-7 col-xxl-7 rounded-2">
                  {loading ? (
                    <div className="fs-7 py-2 pb-3">
                      <SmallLoader />
                    </div>
                  ) : (
                    <DropDown
                      placeholder="Select Assignee"
                      className={`form-select form-select-sm form-select-white border-0 ps-0 py-0 w-auto fs-6 ${
                        loading ? "display-dropdown-loader" : ""
                      }`}
                      displayLoader={loading}
                      options={
                        masterDataList.masterData?.data?.records?.Admin &&
                        masterDataList.masterData?.data?.records?.Article &&
                        masterDataList.masterData?.data?.records?.TeamLeader
                          ? [
                              ...masterDataList.masterData?.data?.records?.Admin?.map(
                                (assignee: any) => {
                                  return {
                                    value: assignee.Id,
                                    name: `${assignee.FirstName} ${assignee.LastName}`,
                                  };
                                }
                              ),
                              ...masterDataList.masterData?.data?.records?.Article?.map(
                                (assignee: any) => {
                                  return {
                                    value: assignee.Id,
                                    name: `${assignee.FirstName} ${assignee.LastName}`,
                                  };
                                }
                              ),
                              ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                (assignee: any) => {
                                  return {
                                    value: assignee.Id,
                                    name: `${assignee.FirstName} ${assignee.LastName}`,
                                  };
                                }
                              ),
                            ]
                          : []
                      }
                      currentValue={{
                        value: dropDownValue.value,
                        name: dropDownValue.name,
                      }}
                      name="taskAssignee"
                      showSearch={true}
                      apiCallDispatch={onChangeAssigneeHandler}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap gap-3 pt-2 pb-3">
              <div className="d-flex gap-2 align-items-center">
                <div className="align-items-center bg-light border d-flex fs-7 fw-semibold py-1 ps-2 pe-4 rounded-pill text-gray-600 min-w-125px">
                  <div className="w-20px me-3">
                    <Avatar
                      firstName={taskDetails.CreatedBy}
                      lastName={""}
                      size={20}
                      color={getColor(taskDetails.CreatedBy).color}
                      background={getColor(taskDetails.CreatedBy).background}
                      length={1}
                      rounded={true}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <span className="fs-7">{taskDetails.CreatedBy}</span>
                    <span className="fs-9 text-gray-400">Creater</span>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <div className="align-items-center bg-light border d-flex fs-7 fw-semibold py-1 ps-2 pe-4 rounded-pill text-gray-600 min-w-125px">
                  <div className="w-20px me-3">
                    <Avatar
                      firstName={taskDetails.ManagerName}
                      lastName={""}
                      size={20}
                      color={getColor(taskDetails.ManagerName).color}
                      background={getColor(taskDetails.ManagerName).background}
                      length={1}
                      rounded={true}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <span className="fs-7">{taskDetails.ManagerName}</span>
                    <span className="fs-9 text-gray-400">Manager</span>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <div className="align-items-center bg-light border d-flex fs-7 fw-semibold py-1 ps-2 pe-4 rounded-pill text-gray-600 min-w-125px">
                  <div className="w-20px me-3">
                    <Avatar
                      firstName={taskDetails.ExpertName}
                      lastName={""}
                      size={20}
                      color={getColor(taskDetails.ExpertName).color}
                      background={getColor(taskDetails.ExpertName).background}
                      length={1}
                      rounded={true}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <span className="fs-7">{taskDetails.ExpertName}</span>
                    <span className="fs-9 text-gray-400">Expert</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-3 flex-wrap mt-4">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setClientMessage({ ...clientMessage, show: true })}
            >
              Send Message
            </button>

            {hasPaymentAccess() && <button
              className="btn btn-sm btn-linkedin"
              onClick={() => setBill({ show: true, edit: false, billId: null, isReimbursement: false })}
            >
              Generate Invoice
            </button>}

            <button
              className="btn btn-sm btn-light btn-active-light-primary"
              onClick={() => {setOpenChat(!openChat);
                        setChatId(Number(params.id))}}
              
            >
              <i className="fa-solid fa-comment-dots fs-3"></i>
              Chat
            </button>
          </div>
        </div>
      </div>
      {chatId != 0 && (
        <TaskChat id={chatId.toString()} setOpenChat={setOpenChat} isDrawer={true} openChat={openChat} />
      )}
      
      <BillingForm
        bill={bill}
        closeModal={closeModal}
        clientDetail={{
          clientId: taskDetails.ClientId,
          clientName: taskDetails.ClientName,
          ContactPersons: taskDetails.ContactPersons,
          taskDetails: taskDetails,
        }}
      />

      <ClientMessagePopup
        closeModal={clientMessagePopupModal}
        clientMessage={clientMessage}
        contactsArr={taskDetails.ContactPersons}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
    openChatId: state.openChat
  };
};

export default connect(mapStateToProps)(TaskCompletionDetails);
