import {
  GET_REIMBURSEMENT_INVOICE_LIST_FAILURE,
  GET_REIMBURSEMENT_INVOICE_LIST_REQUEST,
  GET_REIMBURSEMENT_INVOICE_LIST_SUCCESS,
} from "../actionTypes";

export interface reimbursementInvoiceListState {
  loading: boolean;
  reimbursementInvoiceList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: reimbursementInvoiceListState = {
  loading: false,
  reimbursementInvoiceList: {},
  error: "",
};

export const getReimbursementInvoiceListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_REIMBURSEMENT_INVOICE_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_REIMBURSEMENT_INVOICE_LIST_SUCCESS:
      return {
        loading: false,
        reimbursementInvoiceList: action.payload,
        error: "",
      };
    case GET_REIMBURSEMENT_INVOICE_LIST_FAILURE:
      return {
        loading: false,
        reimbursementInvoiceList: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
