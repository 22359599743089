import { SidebarMenuItem } from "./SidebarMenuItem";
import { absolutePath } from "../../../../../app/helpers/relativePath";
// import hasPermission, {
//   actionsRole,
// } from "../../../../../app/components/pages/auth/core/permission";
import {
  FIRMS_LIST,
  CATEGORY_LIST,
  CLIENT_LIST,
  DAILY_TASK_LIST,
  DOCUMENT_TYPE_LIST,
  GROUP_LIST,
  BILL_LIST,
  TASK_LIST,
  TASK_TYPE_LIST,
  USER_LIST,
  EXPENSE_LIST,
  HOME_PAGE,
  ACTIVITY_LIST,
  PROFORMA_INVOICE_LIST,
  CLIENT_REPORT_LIST,
  TASK_REPORT_LIST,
  DOCUMENT_REPORT_LIST,
  BILL_REPORT_LIST,
  TRANSACTION_REPORT_LIST,
  REIMBURSEMENT_LIST,
} from "../../../../../app/helpers/routes";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import hasPermission, { actionsRole } from "app/components/pages/auth/core/permission";
import hasPaymentAccess from "app/components/pages/auth/core/paymentAccess";

const SidebarMenuMain = () => {
  return (
    <>
     <SidebarMenuItem
          to={absolutePath(HOME_PAGE)}
          title="Dashboard"
          // iconImage={{ path: "/media/task/profile-user-21.png", class: "w-20px" }}
          icon="/media/icons/duotune/art/art002.svg"
        />
      {hasPermission(actionsRole.ADMIN) && (
      <>
        <SidebarMenuItem
          to={absolutePath(USER_LIST)}
          title="Users"
          // iconImage={{ path: "/media/task/profile-user-21.png", class: "w-20px" }}
          icon="/media/icons/duotune/communication/com006.svg"
        />
         <SidebarMenuItem
          to={absolutePath(FIRMS_LIST)}
          title="Firms Master"
          // iconImage={{ path: "/media/task/accounting-book-1.png", class: "w-30px" }}
          // iconImage={{
          //   path: "/media/icons/duotune/finance/fin001.svg",
          //   class: "w-25px",
          // }}
          icon="/media/icons/duotune/finance/fin001.svg"
        />
        {/* <KTSVG path="/media/icons/duotune/communication/com009.svg" className="svg-icon-muted svg-icon-2hx" /> */}
        <SidebarMenuItem
          to={absolutePath(CATEGORY_LIST)}
          title="Categories"
          // iconImage={{ path: "/media/task/slider-navigation.png", class: "w-20px" }}
          // icon="/media/icons/duotune/art/art002.svg"
          icon="/media/icons/duotune/abstract/abs027.svg"
        />
        <SidebarMenuItem
          to={absolutePath(TASK_TYPE_LIST)}
          title="Task Types"
          // iconImage={{
          //   path: "/media/task/icons8-choice-96-1.png",
          //   class: "w-25px",
          // }}
          // iconImage={{ path: "/media/task/paste.png", class: "w-20px" }}
          icon="/media/icons/duotune/layouts/lay008.svg"
        />
       
        <SidebarMenuItem
          to={absolutePath(DOCUMENT_TYPE_LIST)}
          title="Document Types"
          // iconImage={{ path: "/media/task/text-document.png", class: "w-20px" }}
          // iconImage={{
          //   path: "/media/sidebar/icons8-documents-96.png",
          //   class: "w-25px",
          // }}
          icon="/media/icons/duotune/general/gen005.svg"
        />

        <SidebarMenuItem
          to={absolutePath(EXPENSE_LIST)}
          title="Expense Types"
          // iconImage={{
          //   path: "/media/sidebar/icons8-card-wallet-96.png",
          //   class: "w-25px",
          // }}
          // iconImage={{ path: "/media/task/expenses.png", class: "w-25px" }}
          icon="/media/icons/duotune/finance/fin002.svg"
        />
        </>
      )}

       {hasPermission(actionsRole.TEAM_LEAD) && (
          <>
        <SidebarMenuItem
          to={absolutePath(GROUP_LIST)}
          title="Groups"
          // iconImage={{ path: "/media/task/groups.png", class: "w-20px" }}
          icon="/media/icons/duotune/communication/com005.svg"
        />
        <SidebarMenuItem
          to={absolutePath(CLIENT_LIST)}
          title="Clients"
          // iconImage={{ path: "/media/task/clients.png", class: "w-20px" }}
          icon="/media/icons/duotune/general/gen049.svg"
        />
        </>
       )}
        
        {hasPermission(actionsRole.USER) && (
          <>
           <SidebarMenuItem
          to={absolutePath(TASK_LIST)}
          title="Tasks"
          // iconImage={{ path: "/media/task/icons8-checklist-96.png", class: "w-20px" }}
          // iconImage={{
          //   path: "/media/sidebar/icons8-checklist-64.png",
          //   class: "w-25px",
          // }}
          // iconImage={{ path: "/media/task/clipboard.png", class: "w-20px" }}
          icon="/media/icons/duotune/files/fil001.svg"
        />
          <SidebarMenuItem
          // to={absolutePath(`${FIRM_CASH_BOOK_LIST}/${params.id}/transaction/${row.Id}`)}
          to={ACTIVITY_LIST}
          title="Activities"
          // iconImage={{ path: "/media/task/activity-2.png", class: "w-25px" }}
          icon="/media/icons/duotune/abstract/abs042.svg"
        />
        <SidebarMenuItem
          to={absolutePath(DAILY_TASK_LIST)}
          title="Daily Tasks"
          // iconImage={{ path: "/media/task/todo.png", class: "w-25px" }}
          // iconImage={{
          //   path: "/media/task/icons8-checklist-96.png",
          //   class: "w-25px",
          // }}
          icon="/media/icons/duotune/text/txt009.svg"
        />
          </>
        )}
       
        {hasPaymentAccess() && (
          <>
        <SidebarMenuItem
          to={absolutePath(PROFORMA_INVOICE_LIST)}
          title="Proforma Invoices"
          // iconImage={{ path: "/media/task/billing.png", class: "w-25px" }}
          // iconImage={{
          //   path: "/media/task/proforma-invoice.png",
          //   class: "w-25px",
          // }}
          icon="/media/icons/duotune/coding/cod002.svg"
        />

        <SidebarMenuItem 
          to={absolutePath(REIMBURSEMENT_LIST)}
          title="Reimbursements"
          icon="/media/icons/duotune/general/gen005.svg"
        />
        
        <SidebarMenuItem
          to={absolutePath(BILL_LIST)}
          title="Invoices"
          // iconImage={{ path: "/media/task/billing.png", class: "w-25px" }}
          // iconImage={{
          //   path: "/media/sidebar/icons8-invoice-96-3.png",
          //   class: "w-20px",
          // }}
          icon="/media/icons/duotune/finance/fin002.svg"
        />
        </>
        )}
        
        {/* <SidebarMenuItem
            to={absolutePath(PAYMENTS_LIST)}
            title='Activity'
            iconImage={{ path: "", class: "w-25px" }}
          /> */}
        {(hasPermission(actionsRole.TEAM_LEAD) || (hasPermission(actionsRole.USER) && hasPaymentAccess())) && (
          <>
        <SidebarMenuItemWithSub
          to="#"
          title="Reports"
          fontIcon="bi-chat-left"
          icon="/media/icons/duotune/graphs/gra005.svg"
        >
          {hasPermission(actionsRole.TEAM_LEAD) && (
            <>
          <SidebarMenuItem
            to={CLIENT_REPORT_LIST}
            title="Clients"
            hasBullet={true}
          />
          <SidebarMenuItem
            to={TASK_REPORT_LIST}
            title="Tasks"
            hasBullet={true}
          />
          <SidebarMenuItem
            to={DOCUMENT_REPORT_LIST}
            title="Documents"
            hasBullet={true}
          />
          </>
          )}
          
          {hasPaymentAccess() && (
          <>
          <SidebarMenuItem
            to={BILL_REPORT_LIST}
            title="Invoices"
            hasBullet={true}
          />
          <SidebarMenuItem
            to={TRANSACTION_REPORT_LIST}
            title="Transactions"
            hasBullet={true}
          />
          </>
           )}
        </SidebarMenuItemWithSub>
        </>
        )}
    </>
  );
};

export { SidebarMenuMain };
