import { Route, Routes, Navigate } from "react-router-dom";

import {
  ADD_DOCUMENT_TYPE,
  TASK_DETAIL_PAGE,
  ADD_CATEGORY,
  ADD_CLIENT,
  ADD_TASK,
  ADD_TASK_TYPE,
  ADD_USER,
  ADD_GROUP,
  ADD_DAILY_TASK,
  ASSIGNED_TASK_LIST,
  CATEGORY_LIST,
  CLIENT_LIST,
  CONTACT_PERSON_DETAIL_PAGE,
  CLIENT_DOCUMENT_LIST,
  CHECKING_TASK_LIST,
  DOCUMENT_TYPE_LIST,
  DAILY_TASK_LIST,
  EDIT_GROUP,
  EDIT_DOCUMENT_TYPE,
  EDIT_CATEGORY,
  EDIT_CLIENT,
  EDIT_TASK,
  EDIT_TASK_TYPE,
  EDIT_DAILY_TASK,
  EDIT_CATEGORY_TYPES,
  GROUP_LIST,
  GROUP_CONTACT_PERSON_DETAIL_PAGE,
  PROFILE_PAGE,
  SETTINGS,
  TASK_TYPE_LIST,
  TASK_LIST,
  TEMPLATE,
  TEMPLATE_REGISTER,
  USER_LIST,
  VIEW_CATEGORY_TYPES,
  VIEW_DAILY_TASK_BY_ARTICLE,
  WHATSAPP,
  CHANGE_PASSWORD_PAGE,
  FOLLOWUP_TASK_LIST,
  FIRMS_LIST,
  ADD_FIRM,
  EDIT_FIRM,
  FIRM_BANK_BOOK_LIST,
  FIRM_CASH_BOOK_LIST,
  EXPENSE_LIST,
  ADD_EXPENSE,
  EDIT_EXPENSE,
  BILL_LIST,
  HOME_PAGE,
  TASK_DETAIL_PAGE_FROM_HOME,
  ADD_ACTIVITY,
  EDIT_ACTIVITY,
  ACTIVITY_LIST,
  ADD_WHATSAPP_DATA,
  PROFORMA_INVOICE_LIST,
  REIMBURSEMENT_LIST,
  CLIENT_REPORT_LIST,
  CLIENT_REPORT_DETAILS,
  TASK_REPORT_LIST,
  TASK_REPORT_DETAILS,
  DOCUMENT_REPORT_LIST,
  BILL_REPORT_LIST,
  BILL_REPORT_DETAILS,
  TRANSACTION_REPORT_LIST,
  TASK_CONTACT_PERSON_DETAIL_PAGE,
  ADD_MORNING_PLANNER,
  EDIT_MORNING_PLANNER,
  ADD_EVENING_REPORTING,
  EDIT_USER
} from "../helpers/routes";

import MasterLayout from "../../_metronic/layout/MasterLayout";

import AddUser from "../components/pages/user/AddUser";
import AdminMain from "../components/pages/main/admin/AdminMain";
import AdminTaskDetailPage from "../components/pages/main/admin/taskDetail/AdminTaskDetailPage";
import AdminAssignedTable from "app/components/pages/main/admin/assignedTask/AdminAssignedTable";
import AdminCheckingTable from "app/components/pages/main/admin/checkingTask/AdminCheckingTable";

import ClientList from "../components/pages/client/ClientList";
import CategoryList from "../components/pages/categoryWork/CategoryList";
import ContactPersonTable from "../components/pages/client/component/ContactPersonTable";
import CategoryForm from "../components/pages/categoryWork/CategoryForm";
import ClientForm from "../components/pages/client/ClientForm";
import ClientReportList from "app/components/pages/client/ClientReportList";
import ClientReportDetail from "app/components/pages/client/component/ClientReportDetail";

import DocumentTypeList from "../components/pages/documentTypes/DocumentTypeList";
import DocumentTypeForm from "../components/pages/documentTypes/DocumentTypeForm";
import DocumentsList from "app/components/pages/clientDocuments/DocumentsList";
import DocumentsReportList from "app/components/pages/clientDocuments/DocumentsReportList";

import DailyTaskList from "../components/pages/dailyTask/DailyTaskList";
import DailyTaskForm from "../components/pages/dailyTask/DailyTaskForm";
import DailyTaskByArticleTable from "../components/pages/dailyTask/component/DailyTaskByArticleTable";

import Error404 from "../components/pages/errors/components/Error404";

import GroupList from "../components/pages/groups/GroupList";
import GroupForm from "../components/pages/groups/GroupForm";
import GroupContactPerson from "app/components/pages/groups/components/GroupContactPerson";

import ProfilePage from "../components/pages/profile/ProfilePage";

import ChangePassword from "../components/pages/profile/ChangePassword";

import RegisteredTemplateList from "app/components/pages/settings/templateRegister/RegisteredTemplateList";

import TaskTypeList from "../components/pages/taskType/TaskTypeList";
import TaskTypeForm from "../components/pages/taskType/TaskTypeForm";

import TaskList from "../components/pages/task/TaskList";
import TaskForm from "../components/pages/task/TaskForm";
import TypeTable from "../components/pages/categoryWork/component/typeTable/TypeTable";
import TaskReportList from "app/components/pages/task/TaskReportList";

import TemplateRegister from "app/components/pages/settings/templateRegister/TemplateRegister";

import Settings from "app/components/pages/settings/Settings";

import UserList from "../components/pages/user/UserList";

import AdminFollowupTable from "app/components/pages/main/admin/followupTask/AdminFollowupTable";

import FirmMasterList from "app/components/pages/firmMaster/FirmMasterList";
import FirmMasterForm from "app/components/pages/firmMaster/FirmMasterForm";

import CashBookList from "app/components/pages/cashBook/CashBookList";
import CashBookForm from "app/components/pages/cashBook/CashBookForm";

import BankBookList from "app/components/pages/bankBook/BankBookList";
import BankBookForm from "app/components/pages/bankBook/BankBookForm";

import ExpenseForm from "app/components/pages/expense/ExpenseForm";
import ExpenseList from "app/components/pages/expense/ExpenseList";

import BillingList from "app/components/pages/billing/BillingList";
import ProformaInvoiceList from "app/components/pages/proformaInvoice/ProformaInvoiceList";
import ProformaInvoiceDetail from "app/components/pages/proformaInvoice/component/ProformaInvoiceDetail";
import BillingReportList from "app/components/pages/billing/BillingReportList";
import BillingReportDetails from "app/components/pages/billing/component/BillingReportDetails";

import TransactionList from "app/components/pages/transaction/TransactionList";
import TransactionReportList from "app/components/pages/transaction/TransactionReportList";

import ActivityForm from "app/components/pages/activity/ActivityForm";
import ActivityList from "app/components/pages/activity/ActivityList";

import WhatsappAccountDetail from "app/components/pages/settings/whatsappDetails/WhatsappAccountDetail";
import WhatsappDetailsForm from "app/components/pages/settings/whatsappDetails/WhatsappDetailsForm";
import EditUser from "app/components/pages/user/EditUser";
import ReimbursementInvoiceList from "app/components/pages/ReimbursementInvoice/ReimbursementInvoiceList";
import ReimbursementInvoiceDetail from "app/components/pages/ReimbursementInvoice/component/ReimbursementInvoiceDetail";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to={HOME_PAGE} />} />

        {/*Admin */}
        <Route path={ADD_USER} element={<AddUser />} />
        <Route path={`${EDIT_USER}`} element={<EditUser />} />
        <Route path={USER_LIST} element={<UserList />} />

        <Route path={ADD_CLIENT} element={<ClientForm />} />

        <Route path={`${EDIT_CLIENT}/:id`} element={<ClientForm />} />
        <Route path={CLIENT_LIST} element={<ClientList />} />
        <Route path={CLIENT_REPORT_LIST} element={<ClientReportList />} />
        <Route
          path={`${CLIENT_REPORT_DETAILS}/:clientId`}
          element={<ClientReportDetail />}
        />
        <Route
          path={`${CLIENT_REPORT_DETAILS}/:clientId/task/:id`}
          element={<AdminTaskDetailPage />}
        />
        <Route
          path={`${CLIENT_DOCUMENT_LIST}/:id`}
          element={<DocumentsList />}
        />
        <Route path={DOCUMENT_REPORT_LIST} element={<DocumentsReportList />} />

        <Route path={ADD_CATEGORY} element={<CategoryForm />} />
        <Route path={`${EDIT_CATEGORY}/:id`} element={<CategoryForm />} />
        <Route path={CATEGORY_LIST} element={<CategoryList />} />
        <Route path={`${VIEW_CATEGORY_TYPES}/:id`} element={<TypeTable />} />
        <Route path={`${EDIT_CATEGORY_TYPES}/:id`} element={<TaskTypeForm />} />

        <Route path={ADD_TASK_TYPE} element={<TaskTypeForm />} />
        <Route path={`${EDIT_TASK_TYPE}/:id`} element={<TaskTypeForm />} />
        <Route path={TASK_TYPE_LIST} element={<TaskTypeList />} />

        <Route path={ADD_TASK} element={<TaskForm />} />
        <Route path={`${EDIT_TASK}/:id`} element={<TaskForm />} />
        <Route path={TASK_LIST} element={<TaskList />} />
        <Route path={TASK_REPORT_LIST} element={<TaskReportList />} />
        <Route
          path={`${TASK_REPORT_DETAILS}/:id`}
          element={<AdminTaskDetailPage />}
        />

        <Route
          path={`${TASK_DETAIL_PAGE}/:id`}
          element={<AdminTaskDetailPage />}
        />

        <Route path={ADD_DOCUMENT_TYPE} element={<DocumentTypeForm />} />
        <Route
          path={`${EDIT_DOCUMENT_TYPE}/:id`}
          element={<DocumentTypeForm />}
        />
        <Route path={DOCUMENT_TYPE_LIST} element={<DocumentTypeList />} />

        <Route path={ADD_GROUP} element={<GroupForm />} />
        <Route path={`${EDIT_GROUP}/:id`} element={<GroupForm />} />
        <Route path={GROUP_LIST} element={<GroupList />} />
        <Route
          path={`${GROUP_CONTACT_PERSON_DETAIL_PAGE}/:id`}
          element={<GroupContactPerson />}
        />

        <Route path={ADD_DAILY_TASK} element={<DailyTaskForm />} />
        <Route path={`${EDIT_DAILY_TASK}/:id`} element={<DailyTaskForm />} />
        <Route path={DAILY_TASK_LIST} element={<DailyTaskList />} />

        <Route path={SETTINGS} element={<Settings />}>
          <Route path={TEMPLATE} element={<RegisteredTemplateList />} />
          <Route path={WHATSAPP} element={<WhatsappAccountDetail />} />
          <Route path={ADD_WHATSAPP_DATA} element={<WhatsappDetailsForm />} />
          <Route path={TEMPLATE_REGISTER} element={<TemplateRegister />} />
          <Route index element={<RegisteredTemplateList />} />
        </Route>

        <Route path={FIRMS_LIST} element={<FirmMasterList />} />
        <Route path={ADD_FIRM} element={<FirmMasterForm />} />
        <Route path={`${EDIT_FIRM}/:id`} element={<FirmMasterForm />} />
        <Route path={`${FIRM_BANK_BOOK_LIST}/:id`} element={<BankBookList />} />
        <Route path={`${FIRM_CASH_BOOK_LIST}/:id`} element={<CashBookList />} />
        <Route
          path={`${FIRM_CASH_BOOK_LIST}/:id/add`}
          element={<CashBookForm />}
        />
        <Route
          path={`${FIRM_CASH_BOOK_LIST}/:id/edit/:cashBookId`}
          element={<CashBookForm />}
        />
        <Route
          path={`${FIRM_BANK_BOOK_LIST}/:id/add`}
          element={<BankBookForm />}
        />
        <Route
          path={`${FIRM_BANK_BOOK_LIST}/:id/edit/:bankBookId`}
          element={<BankBookForm />}
        />

        <Route path={EXPENSE_LIST} element={<ExpenseList />} />
        <Route path={ADD_EXPENSE} element={<ExpenseForm />} />
        <Route path={`${EDIT_EXPENSE}/:id`} element={<ExpenseForm />} />

        <Route
          path={`${FIRM_CASH_BOOK_LIST}/:id/transaction/:bookId`}
          element={<TransactionList />}
        />
        <Route
          path={`${FIRM_BANK_BOOK_LIST}/:id/transaction/:bookId`}
          element={<TransactionList />}
        />
        <Route
          path={TRANSACTION_REPORT_LIST}
          element={<TransactionReportList />}
        />

        <Route path={BILL_LIST} element={<BillingList />} />
        <Route path={BILL_REPORT_LIST} element={<BillingReportList />} />
        <Route
          path={`${BILL_REPORT_DETAILS}/:billId`}
          element={<BillingReportDetails />}
        />

        <Route path={ADD_ACTIVITY} element={<ActivityForm />} />
        <Route path={`${EDIT_ACTIVITY}/:id`} element={<ActivityForm />} />
        <Route path={ACTIVITY_LIST} element={<ActivityList />} />

        <Route path={PROFORMA_INVOICE_LIST} element={<ProformaInvoiceList />} />
        <Route
          path={`${PROFORMA_INVOICE_LIST}/:id`}
          element={<ProformaInvoiceDetail />}
        />

        <Route path={REIMBURSEMENT_LIST} element={<ReimbursementInvoiceList />} />
        <Route path={`${REIMBURSEMENT_LIST}/:id`} element={<ReimbursementInvoiceDetail />} />

        {/*ADMIN AND TEAM LEAD*/}
        <Route path={HOME_PAGE} element={<AdminMain />}>
          <Route path={ASSIGNED_TASK_LIST} element={<AdminAssignedTable />} />
          <Route path={CHECKING_TASK_LIST} element={<AdminCheckingTable />} />
          <Route path={FOLLOWUP_TASK_LIST} element={<AdminFollowupTable />} />
          <Route index element={<AdminAssignedTable />} />
        </Route>

        {/* // general  */}

        <Route
          path={`${TASK_DETAIL_PAGE_FROM_HOME}/:id`}
          element={<AdminTaskDetailPage />}
        />

        <Route
          path={`${CONTACT_PERSON_DETAIL_PAGE}/:id`}
          element={<ContactPersonTable />}
        />
        <Route
          path={`${TASK_CONTACT_PERSON_DETAIL_PAGE}/:id`}
          element={<ContactPersonTable />}
        />

        <Route
          path={`${VIEW_DAILY_TASK_BY_ARTICLE}/:id`}
          element={<DailyTaskByArticleTable />}
        />

        <Route path={CHANGE_PASSWORD_PAGE} element={<ChangePassword />} />

        <Route path={PROFILE_PAGE} element={<ProfilePage />} />

        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
