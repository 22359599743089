import { connect } from "react-redux";
import { FC, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";

import AddBill from "./component/AddBill";
import {
  addBill,
  getBill,
  getBillList,
  updateBill,
} from "app/reducers/billing/billingAction";
import BillFormat from "./BillFormat";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import { getTaskList } from "app/reducers/task/taskAction";
import Loader from "app/components/commonComponent/loader/Loader";
import { selectedState } from "app/reducers/task/taskSelectionReducer";
import { selectedContactPerson } from "../task/component/TaskGrouping";
import { searchParams } from "app/helpers/commonInterface";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { addProformaInvoice } from "app/reducers/proformaInvoice/proformaInvoiceAction";
import { billDataState } from "app/reducers/billing/getByIdBillReducer";
import { billData } from "app/reducers/billing/billingDataInterface";
import { addReimbursementInvoice } from "app/reducers/reimbursement/reimbursementAction";
import { getSectionsBoundaries } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

export interface addBillValue {
  id?: number;
  tasksIds?: Array<number>;
  clientId: number | undefined;
  firmId: number | undefined;
  discount: number;
  billNumber?: string;
  clientName?: string;
  paidAmount?: string;
  paymentStatus?: string;
  firmName?: string;
  date?: string;
  isProformaInvoice: boolean;
  IsReimbursementInvoice?: boolean | undefined;
}

interface props {
  bill: { show: boolean; edit: boolean; billId: number | null; isReimbursement: boolean | undefined };
  updateBillDispatch: Function;
  addBillDispatch: Function;
  getBillDetailsDispatch: Function;
  billDetails: billDataState;
  selectedTaskDetails: selectedState;
  getAllBillingDetails: Function;
  getAllTaskList: Function;
  closeModal: Function;
  clientDetail?: {
    taskDetails: any;
    clientId: number;
    clientName: string;
    ContactPersons: Array<selectedContactPerson>;
  };
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  addProformaInvoiceDispatch: Function;
  addReimbursementInvoiceDispatch: Function;
}

const getInitialValue = (
  selectedTask: selectedState | number[],
  clientData:
    | { clientId: number; clientName: string; taskDetails: any }
    | undefined,
  params: any
): addBillValue => {
  return {
    tasksIds: !params.id
      ? (selectedTask as selectedState).selected
      : (selectedTask as number[]),
    clientId: clientData ? clientData.clientId : undefined,
    clientName: clientData ? clientData.clientName : "",
    firmId: undefined,
    discount: 0,
    isProformaInvoice: true,
  };
};
const getValueForEdit = (billData: billData | undefined): addBillValue => {
  return {
    id: billData?.Id,
    billNumber: billData?.BillNumber,
    clientId: billData?.ClientId,
    clientName: billData?.ClientName,
    firmId: billData?.Firm?.Id,
    firmName: billData?.Firm?.Name,
    discount: billData?.Discount || 0,
    paidAmount: billData?.PaidAmount ? String(billData?.PaidAmount) : "0",
    paymentStatus: billData?.PaymentStatus
      ? String(billData?.PaymentStatus)
      : "",
    date: billData?.Date,
    isProformaInvoice: billData?.IsProformaInvoice || true,
    IsReimbursementInvoice: billData?.IsReimbursementInvoice
  };
};

const getUniqueClientDataToEditBill = (billDetails: billDataState) => {
  const { arr }: any = billDetails?.bill?.data?.records?.Tasks.reduce(
    (
      acc: { uniqueObj: { [key: string]: boolean }; arr: Array<any> },
      client: { ClientId: number }
    ) => {
      if (!acc.uniqueObj[`${client.ClientId}`]) {
        acc.uniqueObj[`${client.ClientId}`] = true;
        acc.arr.push(client);
      }
      return acc;
    },
    { uniqueObj: {}, arr: [] }
  );
  return arr;
};

const getSelectedTaskList = (selectedTaskDetails: any) => {
  return selectedTaskDetails?.taskListData?.filter((task: { Id: number}) =>
    selectedTaskDetails.selected.includes(task.Id)
  );
};

// const getSelectedTaskList = (selectedTaskDetails: any) => {
//   const updatedBillingStatuses: number[] = [];
//   const selectedTasks = selectedTaskDetails?.taskListData?.filter((task: { Id: number, BillingStatus: number}) => {
//     if (selectedTaskDetails.selected.includes(task.Id)) {
//       updatedBillingStatuses.push(task.BillingStatus); 
//       return true; 
//     }
//     return false; 
//   });

//   selectedTaskDetails.selectedTasksBillingStatus = updatedBillingStatuses;

//   return selectedTasks; 
// };


const getUniqueClientDataToAddBill = (selectedTaskDetails: any) => {
  const { arr } = getSelectedTaskList(selectedTaskDetails).reduce(
    (
      acc: { uniqueObj: { [key: string]: boolean }; arr: Array<any> },
      client: { ClientId: number }
    ) => {
      if (!acc.uniqueObj[`${client.ClientId}`]) {
        acc.uniqueObj[`${client.ClientId}`] = true;
        acc.arr.push(client);
      }
      return acc;
    },
    { uniqueObj: {}, arr: [] }
  );
  return arr;
};

const getUniqueContactPerson = (clientData: any) => {
  const { arr } = clientData.reduce(
    (
      acc: {
        uniqueObj: { [key: string]: boolean };
        arr: Array<selectedContactPerson>;
      },
      client: { ContactPersons: Array<selectedContactPerson> }
    ) => {
      client.ContactPersons.map((contact: selectedContactPerson) => {
        if (!acc.uniqueObj[`${contact.Id}`]) {
          acc.uniqueObj[`${contact.Id}`] = true;
          acc.arr.push(contact);
        }
        return "";
      });
      return acc;
    },
    { uniqueObj: {}, arr: [] }
  );
  return arr;
};

const BillingForm: FC<props> = ({
  masterDataList,
  getAllTaskList,
  getAllBillingDetails,
  clientDetail,
  closeModal,
  selectedTaskDetails,
  billDetails,
  getBillDetailsDispatch,
  addBillDispatch,
  updateBillDispatch,
  bill,
  addProformaInvoiceDispatch,
  addReimbursementInvoiceDispatch,
}) => {

  const [format, setFormat] = useState({ show: false });
  const params = useParams();

  const clientData = useMemo(() => {
    if (bill.edit && billDetails?.bill?.data?.records) {
      return getUniqueClientDataToEditBill(billDetails);
    } else if (!bill.edit) {
      if (clientDetail) {
        return clientDetail;
      } else {
        return getUniqueClientDataToAddBill(selectedTaskDetails);
      }
    }
    return "";
  }, [
    selectedTaskDetails,
    clientDetail,
    bill.edit,
    billDetails?.bill?.data?.records,
  ]);

  const contactPerson: Array<selectedContactPerson> = useMemo(() => {
    if (clientData) {
      if (Array.isArray(clientData)) {
        return getUniqueContactPerson(clientData);
      }
      return clientData.ContactPersons;
    }
    return [];
  }, [clientData]);

  useEffect(() => {
    if (bill.edit && bill.billId) {
      getBillDetailsDispatch(bill.billId);
    }
  }, [getBillDetailsDispatch, bill.edit, bill.billId]);

  const closeBillFormatModal = () => {
    setFormat({ show: false });
  };

  return (
    <ErrorBoundary>
      <Modal
        show={bill.show}
        aria-labelledby="contained-modal-billing"
        centered
        data-toggle="modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            {bill.edit ? "Update Invoice" : "Add Invoice"}
          </Modal.Title>
        </Modal.Header>
        {bill.edit && billDetails.loading && (
          // loader-container
          <div className="py-5">
            <Loader />
          </div>
        )}
        {bill.edit && billDetails.error && (
          <ErrorDisplay error={billDetails.error} />
        )}
        {(bill.edit ? billDetails.bill?.success : true) && (
          <>
            {bill.edit && (
              <AddBill
                initialValue={getValueForEdit(billDetails?.bill?.data?.records)}
                billDispatch={updateBillDispatch}
                title="Update Bill"
                masterDataList={masterDataList}
                clientDetails={clientData}
                closeModal={closeModal}
                bill={bill}
                taskDetails={
                  !clientDetail
                    ? getSelectedTaskList(selectedTaskDetails)
                    : [clientDetail.taskDetails]
                }
                setFormat={setFormat}
                billDetails={billDetails?.bill?.data?.records}
                getAllBillingDetails={getAllBillingDetails}
                getAllTaskList={getAllTaskList}
                addProformaInvoiceDispatch={addProformaInvoiceDispatch}
                addReimbursementInvoiceDispatch={addReimbursementInvoiceDispatch}
                isReimbursement={bill.isReimbursement}
              />
            )}
            {!bill.edit && (
              <AddBill
                initialValue={getInitialValue(
                  !params.id ? selectedTaskDetails : [Number(params.id)],
                  clientDetail,
                  params
                )}
                billDispatch={addBillDispatch}
                title="Generate Invoice"
                masterDataList={masterDataList}
                clientDetails={clientData}
                taskDetails={
                  !clientDetail
                    ? getSelectedTaskList(selectedTaskDetails)
                    : [clientDetail.taskDetails]
                }
                closeModal={closeModal}
                bill={bill}
                setFormat={setFormat}
                getAllBillingDetails={getAllBillingDetails}
                getAllTaskList={getAllTaskList}
                addProformaInvoiceDispatch={addProformaInvoiceDispatch}
                addReimbursementInvoiceDispatch={addReimbursementInvoiceDispatch}
                isReimbursement={bill.isReimbursement}
              />
            )}
          </>
        )}
      </Modal>
      <BillFormat
        formatedBill={format}
        closeModal={closeBillFormatModal}
        contactPersons={contactPerson}
      />
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    billDetails: state.bill,
    selectedTaskDetails: state.selectedTask,
    masterDataList: state.masterData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllTaskList: (searchObj: searchParams) =>
      dispatch(getTaskList(searchObj)),
    getAllBillingDetails: (searchObj: searchParams) =>
      dispatch(getBillList(searchObj)),
    getBillDetailsDispatch: (billId: number) => dispatch(getBill(billId)),
    addBillDispatch: (data: addBillValue, successCallback: Function) =>
      dispatch(addBill(data, successCallback)),
    updateBillDispatch: (data: addBillValue, successCallback: Function) =>
      dispatch(updateBill(data, successCallback)),
    addProformaInvoiceDispatch: (data: any, successCallback: Function) =>
      dispatch(addProformaInvoice(data, successCallback)),
    addReimbursementInvoiceDispatch: (data: any, successCallback: Function) => 
      dispatch(addReimbursementInvoice(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingForm);
