import {
  ErrorMessage,
  Field,
  FormikErrors,
  FormikTouched,
  FormikValues,
} from "formik";
import { FC } from "react";

import { addTaskTypeValue, editTaskTypeValue } from "../TaskTypeForm";
import CustomDatePicker from "../../../commonComponent/datePicker/CustomDatePicker";
import DropDown from "../../../commonComponent/dropDown/DropDown";
import { useParams } from "react-router-dom";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  touched: FormikTouched<addTaskTypeValue>;
  errors: FormikErrors<addTaskTypeValue>;
  setFieldValue: any;
  values: FormikValues;
  taskTypeDetails: editTaskTypeValue | undefined;
  isSubmitting: boolean;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

const OPTIONS = [
  {
    value: "true",
    name: "Repetitive Task",
  },
  {
    value: "false",
    name: "One Time Task",
  },
];

const REPETATION_PERIOD_OPTION = [
  { value: "monthly", name: "Monthly" },
  { value: "quarterly", name: "Quarterly" },
  { value: "half yearly", name: "Half Yearly" },
  { value: "yearly", name: "Yearly" },
];

const TaskTypeDetailForm: FC<props> = ({
  isSubmitting,
  touched,
  errors,
  setFieldValue,
  taskTypeDetails,
  values,
  masterDataList,
}) => {
  const params = useParams();
  return (
    <div className="d-flex flex-column gap-5 gap-md-7">
      <div className="fs-3 fw-semibold mb-n2">Task Type Details</div>
      <div className="row mb-6">
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Type Name
          </label>
          <div className="">
            <Field
              type="text"
              className={`form-control form-control-lg form-control-solid 
              ${touched.name && errors.name && "is-invalid inValidBorder"}`}
              placeholder="Type Name"
              id="name"
              name="name"
              disabled={isSubmitting}
            />
            <ErrorMessage name="name" component="div" className="errorMsg" />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Category
          </label>
          <div className="">
            <DropDown
              className={`text-start form-control form-control-lg form-control-solid form-select ${
                masterDataList.loading ? "display-dropdown-loader" : ""
              }`}
              placeholder="Select Category"
              options={masterDataList.masterData?.data?.records?.Categories?.map(
                (category) => {
                  return { value: category.Id, name: category.Name };
                }
              )}
              displayLoader={masterDataList.loading}
              setFieldValue={setFieldValue}
              name="categoryId"
              currentValue={{ value: values.categoryId }}
              defaultValue={{
                value: taskTypeDetails ? taskTypeDetails.CategoryId : "",
                name: taskTypeDetails ? taskTypeDetails.CategoryName : "",
              }}
              disabled={isSubmitting}
              showSearch={true}
            />
            <ErrorMessage
              name="categoryId"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
      </div>
      <div className="row mb-6">
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6">Article</label>
          <div className="">
            <div className="d-flex flex-lg-wrap flex-md-nowrap flex-sm-wrap flex-xxl-nowrap gap-3">
              <div className="w-100">
                <DropDown
                  placeholder="Select Article"
                  className={`text-start form-control form-control-lg form-control-solid form-select ${
                    masterDataList.loading ? "display-dropdown-loader" : ""
                  }`}
                  displayLoader={masterDataList.loading}
                  options={
                    masterDataList.masterData?.data?.records?.Admin &&
                    masterDataList.masterData?.data?.records?.Article &&
                    masterDataList.masterData?.data?.records?.TeamLeader
                      ? [
                          {
                            value: "",
                            name: "Select Article",
                          },
                          ...masterDataList.masterData?.data?.records?.Admin?.map(
                            (assignee: any) => {
                              return {
                                value: assignee.Id,
                                name: `${assignee.FirstName} ${assignee.LastName}`,
                              };
                            }
                          ),
                          ...masterDataList.masterData?.data?.records?.Article?.map(
                            (assignee: any) => {
                              return {
                                value: assignee.Id,
                                name: `${assignee.FirstName} ${assignee.LastName}`,
                              };
                            }
                          ),
                          ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                            (assignee: any) => {
                              return {
                                value: assignee.Id,
                                name: `${assignee.FirstName} ${assignee.LastName}`,
                              };
                            }
                          ),
                        ]
                      : []
                  }
                  setFieldValue={setFieldValue}
                  name="defaultAssigneeId"
                  currentValue={{ value: values.defaultAssigneeId }}
                  defaultValue={{
                    value: taskTypeDetails
                      ? taskTypeDetails.DefaultAssigneeId
                      : "",
                    name: taskTypeDetails
                      ? taskTypeDetails.DefaultAssigneeName
                      : "",
                  }}
                  disabled={isSubmitting}
                  showSearch={true}
                />
              </div>
              {params.id && (
                <div className="w-100 d-flex align-items-center">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name="changeExistsUser"
                      id="changeExistsUser"
                      disabled={isSubmitting}
                    />
                  </div>
                  <label className="col-form-label fw-semibold fs-6">
                    Change All Existing Task
                  </label>
                </div>
              )}
            </div>
            <ErrorMessage
              name="defaultAssigneeId"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6">Manager</label>
          <div className="d-flex flex-lg-wrap flex-md-nowrap flex-sm-wrap flex-xxl-nowrap gap-3">
            <div className="w-100">
              <DropDown
                placeholder="Select Manager"
                className={`text-start form-control form-control-lg form-control-solid form-select ${
                  masterDataList.loading ? "display-dropdown-loader" : ""
                }`}
                displayLoader={masterDataList.loading}
                options={
                  masterDataList.masterData?.data?.records?.Admin &&
                  masterDataList.masterData?.data?.records?.Article &&
                  masterDataList.masterData?.data?.records?.TeamLeader
                    ? [
                      {
                        value: "",
                        name: "Select Manager",
                      },
                        ...masterDataList.masterData?.data?.records?.Admin?.map(
                          (assignee: any) => {
                            return {
                              value: assignee.Id,
                              name: `${assignee.FirstName} ${assignee.LastName}`,
                            };
                          }
                        ),
                        ...masterDataList.masterData?.data?.records?.Article?.map(
                          (assignee: any) => {
                            return {
                              value: assignee.Id,
                              name: `${assignee.FirstName} ${assignee.LastName}`,
                            };
                          }
                        ),
                        ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                          (assignee: any) => {
                            return {
                              value: assignee.Id,
                              name: `${assignee.FirstName} ${assignee.LastName}`,
                            };
                          }
                        ),
                      ]
                    : []
                }
                setFieldValue={setFieldValue}
                name="defaultManagerId"
                currentValue={{ value: values.defaultManagerId }}
                defaultValue={{
                  value: taskTypeDetails
                    ? taskTypeDetails.DefaultManagerId
                    : "",
                  name: taskTypeDetails
                    ? taskTypeDetails.DefaultManagerName
                    : "",
                }}
                disabled={isSubmitting}
                showSearch={true}
              />
              <ErrorMessage
                name="defaultManagerId"
                component="div"
                className="errorMsg"
              />
            </div>
            {params.id && (
              <div className="w-100 d-flex align-items-center">
                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                  <Field
                    className="form-check-input"
                    type="checkbox"
                    name="changeExistsManager"
                    id="changeExistsManager"
                    disabled={isSubmitting}
                  />
                </div>
                <label className="col-form-label fw-semibold fs-6">
                  Change All Existing Task
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6">Expert</label>
          <div className="d-flex flex-lg-wrap flex-md-nowrap flex-sm-wrap flex-xxl-nowrap gap-3">
            <div className="w-100">
              <DropDown
                placeholder="Select Expert"
                className={`text-start form-control form-control-lg form-control-solid form-select ${
                  masterDataList.loading ? "display-dropdown-loader" : ""
                }`}
                displayLoader={masterDataList.loading}
                options={
                  masterDataList.masterData?.data?.records?.Admin &&
                  masterDataList.masterData?.data?.records?.Article &&
                  masterDataList.masterData?.data?.records?.TeamLeader
                    ? [
                      {
                        value: "",
                        name: "Select Expert",
                      },
                        ...masterDataList.masterData?.data?.records?.Admin?.map(
                          (assignee: any) => {
                            return {
                              value: assignee.Id,
                              name: `${assignee.FirstName} ${assignee.LastName}`,
                            };
                          }
                        ),
                        ...masterDataList.masterData?.data?.records?.Article?.map(
                          (assignee: any) => {
                            return {
                              value: assignee.Id,
                              name: `${assignee.FirstName} ${assignee.LastName}`,
                            };
                          }
                        ),
                        ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                          (assignee: any) => {
                            return {
                              value: assignee.Id,
                              name: `${assignee.FirstName} ${assignee.LastName}`,
                            };
                          }
                        ),
                      ]
                    : []
                }
                setFieldValue={setFieldValue}
                name="defaultExpertId"
                currentValue={{ value: values.defaultExpertId }}
                defaultValue={{
                  value: taskTypeDetails ? taskTypeDetails.DefaultExpertId : "",
                  name: taskTypeDetails
                    ? taskTypeDetails.DefaultExpertName
                    : "",
                }}
                disabled={isSubmitting}
                showSearch={true}
              />
              <ErrorMessage
                name="defaultExpertId"
                component="div"
                className="errorMsg"
              />
            </div>
            {params.id && (
              <div className="w-100 d-flex align-items-center">
                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                  <Field
                    className="form-check-input"
                    type="checkbox"
                    name="changeExistsExpert"
                    id="changeExistsExpert"
                    disabled={isSubmitting}
                  />
                </div>
                <label className="col-form-label fw-semibold fs-6">
                  Change All Existing Task
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row mb-6">
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Standard Fees
          </label>
          <div className="">
            <Field
              type="number"
              className={`form-control form-control-lg form-control-solid`}
              placeholder="Standard Fees"
              id="standardFees"
              name="standardFees"
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="standardFees"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Standard Time
          </label>
          <div className="">
            <div className="d-flex align-items-center gap-2">
              <Field
                type="number"
                className={`form-control form-control-lg form-control-solid 
                ${
                  touched.hoursToComplete &&
                  errors.hoursToComplete &&
                  "is-invalid inValidBorder"
                }`}
                placeholder="Hours"
                id="hoursToComplete"
                name="hoursToComplete"
                disabled={isSubmitting}
              />
              <span className="fw-semibold fs-6">Hours</span>
            </div>
            <ErrorMessage
              name="hoursToComplete"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Task OnBoard Days
          </label>

          <div className="">
            <Field
              type="number"
              className={`form-control form-control-lg form-control-solid`}
              placeholder="Task OnBoard Days"
              id="onboardBefore"
              name="onboardBefore"
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="onboardBefore"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
      </div>
      <div className="row mb-6">
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Time Period
          </label>

          <div className="">
            <DropDown
              className="text-start form-control form-control-lg form-control-solid form-select"
              placeholder="Select Time Period"
              options={OPTIONS}
              setFieldValue={setFieldValue}
              name="isRepeatative"
              currentValue={{ value: String(values.isRepeatative) }}
              defaultValue={{
                value: taskTypeDetails
                  ? String(taskTypeDetails.IsRepeatative)
                  : "",
                name: taskTypeDetails
                  ? taskTypeDetails.IsRepeatative === false
                    ? "One Time Task"
                    : "Repetitive Task"
                  : "",
              }}
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="isRepeatative"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
        {String(values.isRepeatative) === "true" && (
          <div className="col-sm-6 col-lg-4">
            <label className="col-form-label fw-semibold fs-6 required">
              Repetation Period
            </label>

            <div className="">
              <DropDown
                className="text-start form-control form-control-lg form-control-solid form-select"
                placeholder="Select Time Period"
                options={REPETATION_PERIOD_OPTION}
                setFieldValue={setFieldValue}
                name="repeatation"
                currentValue={{ value: values.repeatation }}
                defaultValue={{
                  value: taskTypeDetails ? taskTypeDetails.Repeatation : "",
                  name: taskTypeDetails
                    ? taskTypeDetails.Repeatation.charAt(0).toUpperCase() +
                      taskTypeDetails.Repeatation.slice(1)
                    : "",
                }}
                disabled={isSubmitting}
              />
              <ErrorMessage
                name="repeatation"
                component="div"
                className="errorMsg"
              />
            </div>
          </div>
        )}
        {String(values.isRepeatative) === "false" && (
          <div className="col-sm-6 col-lg-4">
            <label className="col-form-label fw-semibold fs-6 required">
              Ideal Days
            </label>

            <div className="">
              <Field
                type="number"
                name="idealDaysToComplete"
                placeholder="Ideal Days"
                className={`form-control form-control-lg form-control-solid 
                ${
                  touched.idealDaysToComplete &&
                  errors.idealDaysToComplete &&
                  "is-invalid inValidBorder"
                }`}
                disabled={isSubmitting}
              />
              <ErrorMessage
                name="idealDaysToComplete"
                component="div"
                className="errorMsg"
              />
            </div>
          </div>
        )}
        {String(values.isRepeatative) === "true" && (
          <div className="col-sm-6 col-lg-4">
            <label className="col-form-label fw-semibold fs-6 required">
              Government Fixed Date
            </label>
            <div className="">
              <CustomDatePicker
                name="governmentFixDate"
                placeholder="Fixed Date"
                setFieldValue={setFieldValue}
                currentValue={
                  values.governmentFixDate
                    ? new Date(values.governmentFixDate)
                    : null
                }
                disabled={isSubmitting}
              />
              <ErrorMessage
                name="governmentFixDate"
                component="div"
                className="errorMsg"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskTypeDetailForm;
