import { PageLink, PageTitle } from "_metronic/layout/core";
import { absolutePath } from "app/helpers/relativePath";
import { adminHomePage } from "../main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import { KTCard } from "_metronic/helpers";
import ReimbursementInvoiceFilter from "./component/ReimbursementInvoiceFilter";
import ReimbursementInvoiceTable from "./component/ReimbursementInvoiceTable";
import { REIMBURSEMENT_LIST } from "app/helpers/routes";

export const reimbursementInvoiceListPage = {
  title: "Reimbursement Invoice",
  path: absolutePath(REIMBURSEMENT_LIST),
  isSeparator: true,
  isActive: true,
};

export const addToReimbursement: Array<PageLink> = [emptyObject];

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const ReimbursementInvoiceList = () => {
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Reimbursement Invoice</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4 position-relative">
        <UsersListSearchComponent />
        <ReimbursementInvoiceFilter />
      </div>
      <KTCard>
        <ReimbursementInvoiceTable />
      </KTCard>
    </>
  );
};

export default ReimbursementInvoiceList;
