import { connect } from "react-redux";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikState,
  FormikValues,
  useFormikContext,
} from "formik";
import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { object, string, number } from "yup";

import { addTransactionValue } from "../TransactionForm";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { addTransaction } from "app/reducers/transaction/transactionAction";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { TRANSACTION_TYPE } from "app/helpers/constants";
import ErrorBoundary from "app/helpers/ErrorBoundary";

interface props {
  addTransactionDispatch: Function;
  closeModal: Function;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  showTransaction: boolean;
}
const initialValue = {
  bookId: 0,
  amount: "",
  narration: "",
  type: undefined,
  taskId: undefined,
  billId: undefined,
  expenseId: undefined,
  isPayment: "false",
  clientId: undefined,
  firmId: 0,
  isCashBook: "true",
};
interface firmModel {
  Books: {
    Name: string;
    IsCashBook: boolean;
    FirmId: number;
    Id: number;
    IsActive: boolean;
  }[];
  Id: number;
  IsActive: boolean;
  Name: string;
}

const RECEIPT_TYPE_OPTIONS = [
  { value: TRANSACTION_TYPE["Advance"], name: "Advance" },
  { value: TRANSACTION_TYPE["GovernmentFess/Tax"], name: "Govt.Fees/Tax" },
  { value: TRANSACTION_TYPE["Against Bill"], name: "Against Bill" },
  { value: TRANSACTION_TYPE.Other, name: "Other" },
];

const PAYMENT_TYPE_OPTIONS = [
  { value: TRANSACTION_TYPE.Reimbursement, name: "Reimbuse" },
  { value: TRANSACTION_TYPE.Expense, name: "Expense" },
  { value: TRANSACTION_TYPE["GovernmentFess/Tax"], name: "Govt.Fees/Tax" },
  { value: TRANSACTION_TYPE.Other, name: "Other" },
];

const getBookDataBySelectedFirm = (
  firms: firmModel[],
  firmId: number,
  isCashBook: string
) => {
  return firms?.reduce((acc: { value: number; name: string }[], firm) => {
    if (String(firm.Id) == String(firmId)) {
      const firmWiseBookData = firm.Books.reduce(
        (acc1: { value: number; name: string }[], book) => {
          if (String(book.IsCashBook) === String(isCashBook))
            acc1.push({ value: book.Id, name: book.Name });
          return acc1;
        },
        []
      );
      acc.push(...firmWiseBookData);
    }
    return acc;
  }, []);
};

const AddTransactionShrt: FC<props> = ({
  masterDataList,
  closeModal,
  addTransactionDispatch,
  showTransaction,
}) => {
  const [transactionTypeValue, setTransactionTypeValue] = useState<
    TRANSACTION_TYPE | undefined
  >();

  const transactionValidationSchema = object().shape({
    isPayment: string().required("Transaction type is required"),
    type: number()
      .required("Transaction type is required")
      .typeError("Transaction type is required"),
    firmId: number().required("Firm is required"),
    bookId: number().required("Book is required"),
    clientId: number().when("type", {
      is: (type: TRANSACTION_TYPE) =>
        type !== TRANSACTION_TYPE.Expense && type !== TRANSACTION_TYPE.Other,
      then: number().required("Client is required"),
    }),
    billId: number().when("type", {
      is: (type: number) => type === TRANSACTION_TYPE["Against Bill"],
      then: number().required("BillId is required"),
    }),
    expenseId: number().when("type", {
      is: (type: number) => type === TRANSACTION_TYPE.Expense,
      then: number().required("ExpenseId is required"),
    }),
    amount: number()
      .required("Transaction amount is required")
      .typeError("Transaction amount is required "),
    narration: string().when("type", {
      is: (type: number) => type === TRANSACTION_TYPE.Other,
      then: string().required("Narration is requiured"),
    }),
  });

  const GetDataAsPerType = () => {
    const {
      values,
      setFieldValue,
      isSubmitting,
    }: FormikState<FormikValues> & FormikHelpers<FormikValues> =
      useFormikContext();
      
    switch (Number(transactionTypeValue)) {
      case TRANSACTION_TYPE["GovernmentFess/Tax"]:
      case TRANSACTION_TYPE["Advance"]:
      case TRANSACTION_TYPE.Reimbursement:
        return (
          <>
            {values.clientId !== 0 && (
              <div className="mb-6">
                <label className="col-form-label fw-semibold fs-6 required">
                  Task 
                </label>

                <div className="">
                  <DropDown
                    className={`text-start form-control form-control-lg form-control-solid form-select ${
                      masterDataList.loading ? "display-dropdown-loader" : ""
                    }`}
                    placeholder="Select Task"
                    displayLoader={masterDataList.loading}
                    options={masterDataList.masterData?.data?.records?.Tasks?.filter(
                      (task: any) => {
                        return task.ClientId === Number(values.clientId)}
                    ).map((row: { Id: number; TaskCode: string; TaskTypeId: Number}) => {
                      return { value: row.Id, name: row.TaskCode + " " +  masterDataList.masterData?.data?.records.TaskTypes.find( x => x.Id === row.TaskTypeId)?.Name
                      };
                    })}
                    setFieldValue={setFieldValue}
                    name="taskId"
                    currentValue={{ value: values.taskId }}
                    disabled={isSubmitting}
                    showSearch={true}
                  />
                  <ErrorMessage
                    name="taskId"
                    component="div"
                    className="errorMsg"
                  />
                </div>
              </div>
            )}
          </>
        );
      case TRANSACTION_TYPE["Against Bill"]:
        return (
          <>
            {values.clientId !== 0 && (
              <div className="mb-6">
                <label className="col-form-label fw-semibold fs-6 required">
                  Invoice
                </label>

                <div className="">
                  <DropDown
                    className={`text-start form-control form-control-lg form-control-solid form-select ${
                      masterDataList.loading ? "display-dropdown-loader" : ""
                    }`}
                    placeholder="Select Bill"
                    displayLoader={masterDataList.loading}
                    options={masterDataList.masterData?.data?.records?.Bills?.filter(
                      (bill: any) => bill.ClientId === Number(values.clientId)
                    ).map((row: { Id: number; BillNumber: string }) => {
                      return { value: row.Id, name: row.BillNumber };
                    })}
                    setFieldValue={setFieldValue}
                    name="billId"
                    currentValue={{ value: values.billId }}
                    disabled={isSubmitting}
                    showSearch={true}
                  />
                  <ErrorMessage
                    name="billId"
                    component="div"
                    className="errorMsg"
                  />
                </div>
              </div>
            )}
          </>
        );
      case TRANSACTION_TYPE.Expense:
        return (
          <div className="mb-6">
            <label className="col-form-label fw-semibold fs-6 required">
              Expense
            </label>

            <div className="">
              <DropDown
                className={`text-start form-control form-control-lg form-control-solid form-select ${
                  masterDataList.loading ? "display-dropdown-loader" : ""
                }`}
                placeholder="Select Expense"
                displayLoader={masterDataList.loading}
                options={masterDataList.masterData?.data?.records?.Expenses?.map(
                  (row) => {
                    return { value: row.Id, name: row.Name };
                  }
                )}
                setFieldValue={setFieldValue}
                name="expenseId"
                currentValue={{ value: values.expenseId }}
                disabled={isSubmitting}
                showSearch={true}
              />
              <ErrorMessage
                name="expenseId"
                component="div"
                className="errorMsg"
              />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <ErrorBoundary>
      <Modal
        show={showTransaction}
        aria-labelledby="contained-modal-transaction"
        centered
        data-toggle="modal"
        backdrop="static"
        keyboard={false}
        className="transaction-shortcut"
      >
        <Modal.Header>
          <Modal.Title className="">Add Transaction</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={transactionValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            await addTransactionDispatch(
              {
                ...values,
                isPayment: values.isPayment === "false" ? false : true,
                oldIsPayment: null,
              },
              () => {
                resetForm();
              }
            );
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            setFieldValue,
            setValues,
          }) => {
            return (
              <Form noValidate className="form">
                <Modal.Body className="d-flex flex-column justify-content-between modal-body px-9">
                  <div className="d-flex flex-column flex-sm-row gap-5 h-100 justify-content-between">
                    <div className="w-75">
                      <div className="row">
                        <div className="d-flex flex-row gap-3 col-lg-6">
                          <div className="form-check form-check-custom form-check-solid">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="isPayment"
                              value={"false"}
                              onChange={() => {
                                setValues({
                                  ...values,
                                  isPayment: "false",
                                  type: undefined,
                                  taskId: undefined,
                                  expenseId: undefined,
                                  billId: undefined,
                                  clientId: undefined,
                                });
                                setTransactionTypeValue(undefined);
                              }}
                              disabled={isSubmitting}
                            />
                          </div>
                          <div className="col-form-label fw-semibold fs-6">
                            Receipt
                          </div>
                        </div>
                        <div className="d-flex flex-row gap-3 col-lg-6">
                          <div className="form-check form-check-custom form-check-solid">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="isPayment"
                              value={"true"}
                              onChange={() => {
                                setValues({
                                  ...values,
                                  isPayment: "true",
                                  type: undefined,
                                  taskId: undefined,
                                  expenseId: undefined,
                                  billId: undefined,
                                  clientId: undefined,
                                });
                                setTransactionTypeValue(undefined);
                              }}
                              disabled={isSubmitting}
                            />
                          </div>
                          <div className="col-form-label fw-semibold fs-6">
                            Payment
                          </div>
                        </div>
                        <ErrorMessage
                          name="isPayment"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                      <div className="mb-6">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Firm
                        </label>

                        <div className="">
                          <DropDown
                            className={`text-start form-control form-control-lg form-control-solid form-select ${
                              masterDataList.loading
                                ? "display-dropdown-loader"
                                : ""
                            }`}
                            placeholder="Select Firm"
                            displayLoader={masterDataList.loading}
                            options={masterDataList.masterData?.data?.records?.Firms?.map(
                              (firm) => ({ value: firm.Id, name: firm.Name })
                            )}
                            setFieldValue={setFieldValue}
                            apiCallDispatch={(e: any) => {
                              setFieldValue("bookId", 0);
                            }}
                            name="firmId"
                            currentValue={{ value: values.firmId }}
                            disabled={isSubmitting}
                            showSearch={true}
                          />
                          <ErrorMessage
                            name="firmId"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex flex-row gap-3 col-lg-6">
                          <div className="form-check form-check-custom form-check-solid">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="isCashBook"
                              value={"true"}
                              onChange={() => {
                                setFieldValue("isCashBook", "true");
                                setFieldValue("bookId", 0);
                              }}
                              disabled={isSubmitting || !values.firmId}
                            />
                          </div>
                          <div className="col-form-label fw-semibold fs-6">
                            Cash Book
                          </div>
                        </div>
                        <div className="d-flex flex-row gap-3 col-lg-6">
                          <div className="form-check form-check-custom form-check-solid">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="isCashBook"
                              value={"false"}
                              onChange={() => {
                                setFieldValue("isCashBook", "false");
                                setFieldValue("bookId", 0);
                              }}
                              disabled={isSubmitting || !values.firmId}
                            />
                          </div>
                          <div className="col-form-label fw-semibold fs-6">
                            Bank Book
                          </div>
                        </div>
                        <ErrorMessage
                          name="isCashBook"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                      <div className="mb-6">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Book
                        </label>

                        <div className="">
                          <DropDown
                            className="text-start form-control form-control-lg form-control-solid form-select"
                            placeholder="Select Book"
                            options={getBookDataBySelectedFirm(
                              masterDataList.masterData?.data?.records?.Firms,
                              values.firmId,
                              values.isCashBook
                            )}
                            setFieldValue={setFieldValue}
                            name="bookId"
                            currentValue={{ value: values.bookId }}
                            disabled={isSubmitting || !values.firmId}
                            showSearch={true}
                          />
                          <ErrorMessage
                            name="bookId"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div className="mb-6">
                        <label className="col-form-label fw-semibold fs-6">
                          Narration
                        </label>

                        <div className="">
                          <Field
                            as="textarea"
                            placeholder="Narration"
                            className={`form-control form-control-lg form-control-solid vertical-scroll
                         ${
                           touched.narration &&
                           errors.narration &&
                           "is-invalid inValidBorder"
                         }`}
                            name="narration"
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="narration"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="border border-1 border-dashed border-gray-300 d-none d-sm-block mx-7 w-1px"></div>
                    <div className="w-100">
                      <div className="mb-6">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Amount
                        </label>

                        <div className="">
                          <Field
                            type="number"
                            placeholder="Amount"
                            className={`form-control form-control-lg form-control-solid vertical-scroll
                        ${
                          touched.amount &&
                          errors.amount &&
                          "is-invalid inValidBorder"
                        }`}
                            name="amount"
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="amount"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div className="mb-6">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Transaction Type
                        </label>

                        <div className="">
                          <DropDown
                            className="text-start form-control form-control-lg form-control-solid form-select"
                            placeholder="Select Transaction Type"
                            options={
                              values.isPayment === "false"
                                ? RECEIPT_TYPE_OPTIONS
                                : PAYMENT_TYPE_OPTIONS
                            }
                            setFieldValue={setFieldValue}
                            name="type"
                            apiCallDispatch={(e: any) => {
                              //console.log(e, "EVENT");
                              setTransactionTypeValue(e.target.dataset.id);
                              setValues({
                                ...values,
                                type: e.target.dataset.id,
                                taskId: undefined,
                                expenseId: undefined,
                                billId: undefined,
                                clientId: undefined,
                              });
                            }}
                            currentValue={{ value: values.type }}
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="type"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      {values.type &&
                        TRANSACTION_TYPE.Expense !== Number(values.type) &&
                        TRANSACTION_TYPE.Other !== Number(values.type) && (
                          <div className="mb-6">
                            <label className="col-form-label fw-semibold fs-6 required">
                              Client
                            </label>

                            <div className="">
                              <DropDown
                                className={`text-start form-control form-control-lg form-control-solid form-select ${
                                  masterDataList.loading
                                    ? "display-dropdown-loader"
                                    : ""
                                }`}
                                placeholder="Select Client"
                                displayLoader={masterDataList.loading}
                                options={masterDataList.masterData?.data?.records?.Clients?.map(
                                  (client: { Id: number; Name: string }) => {
                                    return {
                                      value: client.Id,
                                      name: client.Name,
                                    };
                                  }
                                )}
                                setFieldValue={setFieldValue}
                                name="clientId"
                                currentValue={{ value: values.clientId }}
                                disabled={isSubmitting}
                                showSearch={true}
                              />
                              <ErrorMessage
                                name="clientId"
                                component="div"
                                className="errorMsg"
                              />
                            </div>
                          </div>
                        )}
                      <GetDataAsPerType />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-3 pt-6">
                    <ResetButton
                      name="Cancel"
                      className="btn btn-light btn-active-light-primary"
                      onClickCallback={() => closeModal()}
                    />
                    <SubmitButton
                      className="btn btn-primary"
                      isSubmitting={isSubmitting}
                      name="Submit"
                    />
                  </div>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    addTransactionDispatch: (
      data: addTransactionValue,
      successCallback: Function
    ) => dispatch(addTransaction(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTransactionShrt);
