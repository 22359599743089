import {
  GET_REIMBURSEMENT_INVOICE_FAILURE,
  GET_REIMBURSEMENT_INVOICE_REQUEST,
  GET_REIMBURSEMENT_INVOICE_SUCCESS,
  UPDATE_REIMBURSEMENT_INVOICE_SUCCESS,
} from "../actionTypes";

export interface reimbursementInvoiceState {
  loading: boolean;
  reimbursementInvoice: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: reimbursementInvoiceState = {
  loading: false,
  reimbursementInvoice: {},
  error: "",
};

export const getReimbursementInvoiceByIdReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_REIMBURSEMENT_INVOICE_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_REIMBURSEMENT_INVOICE_SUCCESS:
    case GET_REIMBURSEMENT_INVOICE_SUCCESS:
      return {
        loading: false,
        reimbursementInvoice: action.payload,
        error: "",
      };
    case GET_REIMBURSEMENT_INVOICE_FAILURE:
      return {
        loading: false,
        reimbursementInvoice: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
