import { combineReducers } from "redux";

//user
// import { addUserReducer } from "./user/addUserReducer"
import { loginReducer } from "./login/loginReducer";
import { profileReducer } from "./profile/profileReducer";
import { signUpReducer } from "./signup/signUpReducer";
import { userListReducer } from "./user/userListReducer";
import { updateUserStatusReducer } from "./user/updateUserStatusReducer";
import { getAssigneeAndCreaterReducer } from "./task/getAssigneeandCreaterReducer";

//category
// import { addCategoryReducer } from "./category/addCategoryReducer"
import { categoryListReducer } from "./category/getCategoryListReducer";
import { getCategoryByIdReducer } from "./category/getCategoryByIdReducer";
// import { updateCategoryReducer } from "./category/updateCategoryReducer"

//client
// import { addClientReducer } from "./client/addClientReducer"
import { clientListReducer } from "./client/getClientListReducer";
import { getClientByIdReducer } from "./client/getClientByIdReducer";
// import { updateClientReducer } from "./client/updateClientReducer"
import { clientDocumentsReducer } from "./clientDocuments/clientDocumentsReducer";
import { getClientReportDetailsByIdReducer } from "./client/getClientReportDetailsReducer";
import { clientDocumentsReportListReducer } from "./clientDocuments/clientDocumentsReportListReducer";
import { clientDocumentSelectionReducer } from "./clientDocuments/clientDocumentSelectionReducer";

//task
// import { addTaskReducer } from "./task/addTaskReducer"
// import { updateTaskReducer } from "./task/updateTaskReducer"
import { taskListReducer } from "./task/getTaskListReducer";
import { getTaskByIdReducer } from "./task/getTaskByIdReducer";
import { getFollowupTaskReducer } from "./task/getFollowupTaskReducer";
import { getAssignedTaskReducer } from "./task/getAssignedTaskReducer";
import { taskSelectionReducer } from "./task/taskSelectionReducer";
import { getCheckingTaskReducer } from "./task/getCheckingTaskReducer";
import { getTaskReportListReducer } from "./task/getTaskReportListReducer";

//task type
// import { addTaskTypeReducer } from "./taskType/addTaskTypeReducer"
import { taskTypeListReducer } from "./taskType/getTaskTypeListReducer";
import { getTaskTypeByIdReducer } from "./taskType/getTaskTypeByIdReducer";
import { updateTaskTypeReducer } from "./taskType/updateTaskTypeReducer";

//document type
// import { addEditDocumentTypeReducer } from "./documentType/addEditDocumentTypeReducer"
import { getDocumentTypeByIdReducer } from "./documentType/getDocumentType";
import { documentTypeListReducer } from "./documentType/getDocumentTypeList";

//group
// import { addEditGroupReducer } from "./groups/addEditGroupReducer"
import { getGroupByIdReducer } from "./groups/getGroupReducer";
import { groupListReducer } from "./groups/getGroupListReducer";

//morning planner
import {morningPlannerReducer} from "./morningPlanner/getMorningPlannerReducer";
import {usersMorningPlannerReducer} from "./morningPlanner/getUsersMorningPlannerReducer";
import { eveningReportingReducer } from "./morningPlanner/getEveningReportingReducer";

//daily task
// import { addEditDailyTaskReducer } from "./dailyTask/addEditDailyTaskReducer"
import { getDailyTaskByIdReducer } from "./dailyTask/getByIdDailyTaskReducer";
import { dailyTaskListReducer } from "./dailyTask/getDailyTaskListReducer";

//wp
import { whatsappTemplateDataListReducer } from "./whatsapp/whatsappTemplateDataListReducer";

// firm
// import { addUpdateFirmReducer } from "./firmMaster/addUpdateFirmReducer"
import { getFirmByIdReducer } from "./firmMaster/getFirmByIdReducer";
import { getFirmListReducer } from "./firmMaster/getFirmListReducer";

//bank book
// import { addUpdateBankBookReducer } from "./bankBook/addUpdateBankBook"
import { getBankBookListReducer } from "./bankBook/getBankBookListReducer";
import { getByIdBankBookReducer } from "./bankBook/getByIdBankBookReducer";

//cash book
// import { addUpdateCashBookReducer } from "./cashBook/addUpdateCashBookReducer"
import { getByIdCashBookReducer } from "./cashBook/getByIdCashBookReducer";
import { getCashBookListReducer } from "./cashBook/getCashBookListReducer";

//cash book
import { getExpenseListReducer } from "./expense/getExpenseListReducer";
import { getExpenseByIdReducer } from "./expense/getByIdExpenseReducer";

//transaction
import { getTransactionListReducer } from "./transaction/getTransactionListReducer";
import { getTransactionByIdReducer } from "./transaction/getByIdTransactionReducer";
import { getTransactionReportListReducer } from "./transaction/getTransactionReportListReducer";

//bill
import { getBillListReducer } from "./billing/getBillListReducer";
import { getByIdBillReducer } from "./billing/getByIdBillReducer";
import { addUpdateBillReducer } from "./billing/addUpdateBillReducer";
import { saveBillPdfReducer } from "./billing/saveBillPdfReducer";
import { getBillReportListReducer } from "./billing/getBillReportListReducer";

import { getMasterDataReducer } from "./masterData/masterDataReducer";

import { getActivityReducer } from "./activity/getActivityReducer";
import { getActivityListReducer } from "./activity/getActivityListReducer";
import { getActivityByArticleReducer } from "./activity/getActivityByArticleReducer";
import { getPlannerActivityReducer } from "./activity/gatPlannerActivityReducer";

import { chatListReducer } from "./chat/getAllChatMsgList";
import { websocketReducer } from "./websocket/websocketReducer";

import { getWhatsappAccountDetailReducer } from "./whatsapp/getWhatsappAccountDetails";

import { getProformaInvoiceListReducer } from "./proformaInvoice/getProformaInvoiceListReducer";
import { getProformaInvoiceByIdReducer } from "./proformaInvoice/getProformaInvoiceByIdReducer";
import { addUpdateProformaInvoiceReducer } from "./proformaInvoice/addUpdateProformaInvoiceReducer";

import { getReimbursementInvoiceListReducer } from "./reimbursement/getReimbursementInvoiceListReducer";
import { getReimbursementInvoiceByIdReducer } from "./reimbursement/getReimbursementInvoiceByIdReducer";

import { usersEveningReportingReducer } from "./morningPlanner/getUersEveningReportingReduces";
import { usersByReportingAuthorityListReducer } from "./user/usersByReportingAuthorityListReducer";
import { getplannerTaskReducer } from "./task/getPlannertaskReducer";
import { morningPlannerByIdReducer } from "./morningPlanner/getMorningPlannerByIdReducer";
import { addMorningPlannerReducer } from "./morningPlanner/addMorningPlannerReducer";
import { editMorningPlannerReducer } from "./morningPlanner/editMorningPlannerReducer";
import { eveningReportingByIdReducer } from "./morningPlanner/getEveningReportingByIdReducer";
import { addEveningReportingReducer } from "./morningPlanner/addEveningReportingReducer";
import { editEveningReportingReducer } from "./morningPlanner/editEveningReportingReducer";
import { viewMorningActionReducer } from "./morningPlanner/viewMorningAction";
import { viewEveningActionReducer } from "./morningPlanner/viewEveningAction";
import { getUserByIdReducer } from "./profile/getUserByIdReducer";
import { editUserReducer } from "./profile/editUserReducer";
import { getLegalStatusListReducer } from "./client/getLegalStatusListReducer";
import { getChatNotificationReducer } from "./task/getChatNotificationReducer";
import { openChatReducer } from "./chat/openChatReducer";
import { urgentStatusReducer } from "./task/setUgentStatus";
import { urgentTaskListReducer } from "./task/urgentTaskReducer";
import { dailyMorningPlannerReducer } from "./morningPlanner/getDailyMorningPlannnerReducer";
import { getAllDailyMorningPlannerReducer } from "./morningPlanner/getAllDailyMorningPlannerReducer";

const rootReducer = combineReducers({
  //user
  login: loginReducer,
  signUp: signUpReducer,
  // addUser: addUserReducer,
  profile: profileReducer,
  userById:getUserByIdReducer,
  userList: userListReducer,
  editUser : editUserReducer,
  usersByReportingAuthorityList: usersByReportingAuthorityListReducer,
  updateStatus: updateUserStatusReducer,
  assigneeAndCreater: getAssigneeAndCreaterReducer,

  //category
  // addCategory: addCategoryReducer,
  categoryList: categoryListReducer,
  category: getCategoryByIdReducer,
  // updateCategory: updateCategoryReducer,

  //client
  // addClient: addClientReducer,
  legalStatusList:getLegalStatusListReducer,
  clientList: clientListReducer,
  client: getClientByIdReducer,

  // updateClient: updateClientReducer,
  clientDocumentList: clientDocumentsReducer,
  selectedDocumentList: clientDocumentSelectionReducer,
  clientReportDetails: getClientReportDetailsByIdReducer,
  clientDocumentReportDetails: clientDocumentsReportListReducer,

  //task
  // addTask: addTaskReducer,
  taskList: taskListReducer,
  // updateTask: updateTaskReducer,

  openChat : openChatReducer,
  chatNotificationTasks:getChatNotificationReducer,
  task: getTaskByIdReducer,
  assignedTask: getAssignedTaskReducer,
  checkingTask: getCheckingTaskReducer,
  followupTask: getFollowupTaskReducer,
  selectedTask: taskSelectionReducer,
  taskReportDetails: getTaskReportListReducer,
  plannerTask:getplannerTaskReducer,
  urgentStatus : urgentStatusReducer,
  //tasktype
  // addTaskType: addTaskTypeReducer,
  taskTypeList: taskTypeListReducer,
  updateTaskType: updateTaskTypeReducer,
  taskType: getTaskTypeByIdReducer,
  urgentTaskList : urgentTaskListReducer,

  //document type
  // addEditDocumentType: addEditDocumentTypeReducer,
  documentType: getDocumentTypeByIdReducer,
  documentTypeList: documentTypeListReducer,

  //group
  // addEditGroup: addEditGroupReducer,
  group: getGroupByIdReducer,
  groupList: groupListReducer,

  //morning planner
  getAllDailyMorningPlanner : getAllDailyMorningPlannerReducer,
  dailyMorningPlannerList:dailyMorningPlannerReducer,
  morningPlannerList:morningPlannerReducer,
  morningPlannerById: morningPlannerByIdReducer,
  addMorningPlanner:addMorningPlannerReducer,
  editMorningPlanner:editMorningPlannerReducer,
  usersMorningPlannerList: usersMorningPlannerReducer,
  viewMorningAction : viewMorningActionReducer,

  addEveningReporting:addEveningReportingReducer,
  eveningReportingById:eveningReportingByIdReducer,
  usersEveningReportingList:usersEveningReportingReducer,
  eveningReportingList:eveningReportingReducer,
  editEveningReporting : editEveningReportingReducer,
  viewEveningAction:viewEveningActionReducer,
  //daily task
  // addEditDailyTask: addEditDailyTaskReducer,
  dailyTask: getDailyTaskByIdReducer,
  dailyTaskList: dailyTaskListReducer,

  //wp
  wpTemplateList: whatsappTemplateDataListReducer,

  //account
  // addUpdateFirm: addUpdateFirmReducer,
  firm: getFirmByIdReducer,
  firmList: getFirmListReducer,

  //bank book
  // addUpdateBankBook: addUpdateBankBookReducer,
  bankBook: getByIdBankBookReducer,
  bankBookList: getBankBookListReducer,

  //cash book
  // addUpdateCashBook: addUpdateCashBookReducer,
  cashBook: getByIdCashBookReducer,
  cashBookList: getCashBookListReducer,

  //expense
  expenseList: getExpenseListReducer,
  expense: getExpenseByIdReducer,

  //transaction
  transactionList: getTransactionListReducer,
  transaction: getTransactionByIdReducer,
  transactionReportDetails: getTransactionReportListReducer,

  //bill
  addUpdateBill: addUpdateBillReducer,
  billList: getBillListReducer,
  bill: getByIdBillReducer,
  saveBillPdf: saveBillPdfReducer,
  billReportDetails: getBillReportListReducer,

  masterData: getMasterDataReducer,

  activity: getActivityReducer,
  activityList: getActivityListReducer,
  articleActivity: getActivityByArticleReducer,
  plannerActivity : getPlannerActivityReducer,
  // addUpdateList : addUpdateActivityReducer

  chatList: chatListReducer,

  whatsappAccountDetail: getWhatsappAccountDetailReducer,

  websocketData: websocketReducer,

  proformaInvoiceList: getProformaInvoiceListReducer,
  proformaInvoice: getProformaInvoiceByIdReducer,
  addUpdateProformaInvoice: addUpdateProformaInvoiceReducer,

  reimbursementInvoiceList: getReimbursementInvoiceListReducer,
  reimbursementInvoice: getReimbursementInvoiceByIdReducer,
});

export default rootReducer;
