import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { object, number, string } from "yup";
import { useSearchParams } from "react-router-dom";

import { addBillValue } from "../BillingForm";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { getSearchParameter } from "app/helpers/helperFunction";
import { PAYMENT_STATUS } from "app/helpers/constants";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";
import { addReimbursementInvoice } from "app/reducers/reimbursement/reimbursementAction";

interface clientData {
  ClientId: number;
  ClientName: string;
}
interface props {
  initialValue: addBillValue;
  billDispatch: Function;
  title: string;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  clientDetails: clientData | Array<clientData>;
  taskDetails: any;
  closeModal: Function;
  bill: { show: boolean; edit: boolean; billId: null | number };
  setFormat: Function;
  billDetails?: any;
  getAllBillingDetails: Function;
  getAllTaskList: Function;
  addProformaInvoiceDispatch: Function;
  addReimbursementInvoiceDispatch: Function;
  isReimbursement: boolean | undefined;
}

const BILL_STATUS_OPTIONS = [
  { value: PAYMENT_STATUS["Partially Paid"], name: "Partially Paid" },
  { value: PAYMENT_STATUS["Fully Paid"], name: "Fully Paid" },
];

const AddBill: FC<props> = ({
  getAllTaskList,
  getAllBillingDetails,
  taskDetails,
  billDetails,
  setFormat,
  bill,
  closeModal,
  clientDetails,
  masterDataList,
  initialValue,
  billDispatch,
  title,
  addProformaInvoiceDispatch,
  addReimbursementInvoiceDispatch,
  isReimbursement,
}) => {
  const [searchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );
  const sendDataChangeWarning = useSendDataChangeWarning();

  const billValidationSchema = object().shape({
    clientId: number().required("Client is requird"),
    firmId: number().required("Firm is required"),
    discount: number()
      .required("Discount is required")
      .typeError("Discount is required"),
    paidAmount: string().test(
      "paidAmountRequired",
      "Amount paid is required",
      (value) => {
        if (value === "" && bill.edit) return false;
        return true;
      }
    ),
    status: string().test("required", "Status is required", (value) => {
      if (value === "" && bill.edit) return false;
      return true;
    }),
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValue}
        validationSchema={billValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          console.log(values, "VALUES OF FORM");
          const updatedValues = {
            ...values,
            IsReimbursementInvoice: values.hasOwnProperty('IsReimbursementInvoice') ? values.IsReimbursementInvoice : true
          };
          console.log(updatedValues, "UPDATED VALUE");
          if (bill.edit) {
            await billDispatch(updatedValues, () => {
              (String(billDetails.ClientId) !== String(values.clientId) ||
                String(billDetails.Firm.Id) !== String(values.firmId) ||
                String(billDetails.Discount) !== String(values.discount)) &&
                setFormat({ show: true });
              resetForm();
              getAllBillingDetails(searchObj);
              closeModal();
              sendDataChangeWarning();
            });
          } else if (!updatedValues.isProformaInvoice && !updatedValues.IsReimbursementInvoice) {
            await billDispatch(updatedValues, () => {
              setFormat({ show: true });
              resetForm();
              getAllTaskList(searchObj);
              closeModal();
              sendDataChangeWarning();
            });
          } else if (
            updatedValues.isProformaInvoice &&
            !updatedValues.IsReimbursementInvoice
          ) {
            await addProformaInvoiceDispatch(updatedValues, () => {
              // setFormat({ show: true });
              resetForm();
              getAllTaskList(searchObj);
              closeModal();
              // sendDataChangeWarning();
            });
          // } else if (values.IsReimbursementInvoice) {
          } else {
            await addReimbursementInvoiceDispatch(updatedValues, () => {
              resetForm();
              getAllTaskList(searchObj);
              closeModal();
            });
          }
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue }) => {
          return (
            <Form noValidate className="form">
              <Modal.Body>
                <div className="">
                  {!bill.edit && (
                    <>
                      <div className="row mb-6">
                        {!isReimbursement && (
                          <>
                            <label className="col-sm-3 col-form-label fw-semibold fs-6 required">
                              Save as
                            </label>
                            <div className="col-sm-9 row">
                              <div className="d-flex flex-row gap-3 col-sm-6">
                                <div className="form-check form-check-custom form-check-solid">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="isProformaInvoice"
                                    value={true}
                                    checked={
                                      String(values.isProformaInvoice) ===
                                        "true" &&
                                      String(values.IsReimbursementInvoice) ===
                                        "false"
                                    }
                                    onChange={() => {
                                      setFieldValue("isProformaInvoice", true);
                                      setFieldValue(
                                        "IsReimbursementInvoice",
                                        false
                                      );
                                    }}
                                    disabled={isSubmitting}
                                  />
                                </div>
                                <div className="col-form-label fw-semibold fs-6">
                                  Proforma Invoice
                                </div>
                              </div>
                              <div className="d-flex flex-row gap-3 col-sm-6">
                                <div className="form-check form-check-custom form-check-solid">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="isProformaInvoice"
                                    value={false}
                                    checked={
                                      String(values.isProformaInvoice) ===
                                        "false" &&
                                      String(values.IsReimbursementInvoice) ===
                                        "false"
                                    }
                                    onChange={() => {
                                      setFieldValue("isProformaInvoice", false);
                                      setFieldValue(
                                        "IsReimbursementInvoice",
                                        false
                                      );
                                    }}
                                    disabled={isSubmitting}
                                  />
                                </div>
                                <div className="col-form-label fw-semibold fs-6">
                                  Final Invoice
                                </div>
                              </div>
                              {/* <div className="d-flex flex-row gap-3 col-sm-6">
                                <div className="form-check form-check-custom form-check-solid">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="IsReimbursementInvoice"
                                    value={true}
                                    checked={
                                      String(values.IsReimbursementInvoice) ===
                                      "true"
                                    }
                                    onChange={() => {
                                      setFieldValue(
                                        "IsReimbursementInvoice",
                                        true
                                      );
                                    }}
                                    disabled={isSubmitting}
                                  />
                                </div>
                                <div className="col-form-label fw-semibold fs-6">
                                  Reimbursement
                                </div>
                              </div> */}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                          Client
                        </label>

                        {Array.isArray(clientDetails) && (
                          <div className="col-lg-9 fv-row">
                            <DropDown
                              className="text-start form-control form-control-lg form-control-solid form-select"
                              placeholder="Select Client"
                              options={clientDetails?.map(
                                (client: clientData) => {
                                  return {
                                    value: Number(client.ClientId),
                                    name: client.ClientName,
                                  };
                                }
                              )}
                              setFieldValue={setFieldValue}
                              name="clientId"
                              currentValue={{ value: values.clientId }}
                              defaultValue={{
                                value: billDetails ? values.clientId : "",
                                name: billDetails ? values.clientName : "",
                              }}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="clientId"
                              component="div"
                              className="errorMsg"
                            />
                          </div>
                        )}
                        {!Array.isArray(clientDetails) && (
                          <div className="col-lg-9 fv-row">
                            <Field
                              type="text"
                              placeholder="Client"
                              className="form-control form-control-lg form-control-solid"
                              value={values.clientName}
                              name="clientId"
                              id="clientId"
                              disabled
                            />
                          </div>
                        )}
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                          Firm
                        </label>

                        <div className="col-lg-9 fv-row">
                          <DropDown
                            className={`text-start form-control form-control-lg form-control-solid form-select ${
                              masterDataList.loading
                                ? "display-dropdown-loader"
                                : ""
                            }`}
                            placeholder="Select Firm"
                            options={masterDataList.masterData?.data?.records?.Firms?.map(
                              (firm: { Id: number; Name: string }) => {
                                return {
                                  value: Number(firm.Id),
                                  name: firm.Name,
                                };
                              }
                            )}
                            displayLoader={masterDataList.loading}
                            setFieldValue={setFieldValue}
                            name="firmId"
                            currentValue={{ value: Number(values.firmId) }}
                            defaultValue={{
                              value: billDetails ? Number(values.firmId) : "",
                              name: billDetails ? values.firmName : "",
                            }}
                            disabled={isSubmitting}
                            showSearch={true}
                          />
                          <ErrorMessage
                            name="firmId"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      {!isReimbursement && (
                        <div className="row mb-6">
                          <label className="col-lg-3 col-form-label fw-semibold fs-6">
                            Discount
                          </label>

                          <div className="col-lg-9">
                            <Field
                              type="number"
                              placeholder="Discount"
                              className={`form-control form-control-lg form-control-solid
                        ${
                          touched.discount &&
                          errors.discount &&
                          "is-invalid inValidBorder"
                        }`}
                              name="discount"
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="discount"
                              component="div"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {bill.edit && (
                    <>
                      <div className="row mb-6">
                        <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                          Paid Amount
                        </label>

                        <div className="col-lg-9">
                          <Field
                            type="number"
                            placeholder="Paid Amount"
                            className={`form-control form-control-lg form-control-solid
                            ${
                              touched.paidAmount &&
                              errors.paidAmount &&
                              "is-invalid inValidBorder"
                            }`}
                            name="paidAmount"
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="paidAmount"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                          Status
                        </label>

                        <div className="col-lg-9">
                          <DropDown
                            className="text-start form-control form-control-lg form-control-solid form-select"
                            placeholder="Select Status"
                            options={BILL_STATUS_OPTIONS}
                            setFieldValue={setFieldValue}
                            name="paymentStatus"
                            currentValue={{ value: values.paymentStatus }}
                            defaultValue={{
                              value: billDetails ? values.paymentStatus : "",
                              name:
                                billDetails && values.paymentStatus
                                  ? PAYMENT_STATUS[Number(values.paymentStatus)]
                                  : "",
                            }}
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="paymentStatus"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-end pt-6 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() => closeModal()}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    className="btn btn-primary"
                    name="Submit"
                  />
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddBill;
