//profile picture api
export const PROFILE_PICTURE_API_ENDPOINT = "https://ui-avatars.com/api/";

// auth user
export const ADD_USER_API_ENDPOINT = "/Auth/Register";

export const LOGIN_API_ENDPOINT = "/Auth/Login";

export const PROFILE_API_ENDPOINT = "/Auth/Profile";

export const UPDATE_PROFILE_API_ENDPOINT = "/Auth/UpdateUser";

export const UPDATE_USER_STATUS_API_ENDPOINT = "/Auth/UpdateStatus";

export const FORGOT_PASSWORD_API_ENDPOINT = "/Auth/ForgotPassword";

export const RESET_PASSWORD_API_ENDPOINT = "/Auth/ResetPassword";

// get user details
export const USER_LIST_API_ENDPOINT = "/Company";

export const USER_LIST_REPORTED_AUTHORITY_API_ENDPOINT = "/Company/UsersByReportingAuthority"

// client
export const CLIENT_API_ENDPOINT = "/ClientDetails";

export const CLIENT_REPORT_DETAILS_API_ENDPOINT = "/ClientDetails/Report";

export const CLIENT_GET_ALL_API_ENDPOINT = "/ClientDetails/GetAll";

export const LEGALSTATUS_GET_ALL_API_ENDPOINT = "/LegalStatus/GetAll";

export const UPDATE_CLIENT_CONTACT_PERSON_API_ENDPOINT =
  "/ClientDetails/ContactPerson";

export const CLIENT_IMPORT_DETAILS = "/ClientDetails/Import";

// category
export const GET_ALL_CATEGORY_WORK_API_ENDPOINT = "/WorkCategory/GetAll";

export const CATEGORY_WORK_API_ENDPOINT = "/WorkCategory";

//task type
export const TASK_TYPE_API_ENDPOINT = "/TaskType";

export const GET_ALL_TASK_TYPE_API_ENDPOINT = "/TaskType/GetAll";

// morning planner
export const GET_MORNING_PLANNER_ENDPOINT ='/MorningPlanner/GetMorningPlanner'

export const GET_DAILY_MORNING_PLANNER_ENDPOINT ='/MorningPlanner/GetUserDailyMorningPlannerTask'

export const GET_ALL_DAILY_MORNING_PLANNER_ENDPOINT ='/MorningPlanner/GetAllDailyMorningPlanner'

export const DELTE_MORNING_PLANNER_ENDPOINT ='/MorningPlanner'

export const DELTE_EVENING_REPORTING_ENDPOINT ='/EveningReporting'

export const GET_MORNING_PLANNER_BY_ID_ENDPOINT ='/MorningPlanner'

export const GET_EVENING_REPORING_BY_ID_ENDPOINT ='/EveningReporting'

export const GET_USER_EVENING_PLANNER_ENDPOINT ='/EveningReporting/GetEveningReporting'

export const GET_USERS_MORNING_PLANNER_ENDPOINT='/MorningPlanner/GetUsersMorningPlanner'

export const GET_USERS_EVENING_REPORTING_ENDPOINT='/EveningReporting/GetUsersEveningReporting'

export const ADD_MORNING_PLANNER_ENDPOINT='/MorningPlanner'

export const EDIT_MORNING_PLANNER_ENDPOINT='/MorningPlanner'

export const EDIT_EVENING_REPORTING_ENDPOINT='/EveningReporting'

export const ADD_EVENING_REPORTING_ENDPOINT='/EveningReporting'

// task
export const TASK_API_ENDPOINT = "/WorkTask";

export const CHAT_NOTIFICATION_TASK_API_ENDPOINT = "/Chat/ChatNotificationTasks";

export const ASSIGNED_TASK_API_ENDPOINT = "/WorkTask/Assigned";

export const GET_ALL_TASK_API_ENDPOINT = "/WorkTask/GetAll";

export const CHANGE_TASK_ASSIGNEE_API_ENDPOINT = "/WorkTask/Assign";

export const CHANGE_TASK_STATUS_API_ENDPOINT = "/WorkTask/Status";

export const TASK_FOR_CHECKING_API_ENDPOINT = "/WorkTask/UpdateStatus";

export const FILE_API_ENDPOINT = "/WorkTask/File";

export const WORKTASK_ACTIVITY_STATUS_API_ENDPOINT = "/WorkTask/ActivityStatus";

export const SEND_OTP_REQUEST_API_ENDPOINT = "/WorkTask/SendOTP";

export const UPDATE_BILL_STATUS_API_ENDPOINT = "/WorkTask/UpdateBillingStatus";

export const SET_URGENT_STATUS = "/WorkTask/SetUrgent";

export const GET_URGENT_TASK = '/WorkTask/UrgentTask';

export const TASK_REPORT_DETAILS_API_ENDPOINT = "/WorkTask/Report";

export const DOCUMENT_REPORT_API_ENDPOINT = "/WorkTask/File/Report";

//remarks
export const REMARKS_API_ENDPOINT = "/Remarks";

export const FILE_UPLOAD_TO_REMARKS_API_ENDPOINT = "/Remarks/file";

//document type
export const GET_ALL_DOCUMENT_TYPE_API_ENDPOINT = "/DocumentType/GetAll";

export const DOCUMENT_TYPE_API_ENDPOINT = "/DocumentType";

// group
export const GROUP_AP_ENDPOINT = "/Group";

export const GROUP_LIST_AP_ENDPOINT = "/Group/GetAll";

//dailytask or todo
export const DAILY_TASK_API_ENDPOINT = "/Todo";

export const DAILY_TASK_LIST_API_ENDPOINT = "/Todo/GetAll";

export const GET_DAILY_TASK_BY_ASSIGNEE_API_ENDPOINT = "/Todo/Assignee";

export const MARK_DAILY_TASK_COMPLETED = "/Todo/Complete";

// whatsapp
export const WHATSAPP_TEMPLATE_REGISTER_API_ENDPOINT = "/WhatsApp/Template";

export const WHATSAPP_SEND_MESSAGE_API_ENDPOINT = "/WhatsApp/Send";

export const WHATSAPP_TEMPLATE_LIST_API_ENDPOINT = "/WhatsApp";

// file
export const DOWNLOAD_FILE_API_ENDPOINT = "/File/Download";

export const GENERAL_FILE_UPLOAD_API_ENDPOINT = "/File/Upload";

export const DOWNLOAD_ZIP_FILE_API_ENDPOINT = "/File/Download/Zip";

//firm
export const FIRM_API_ENDPOINT = "/Firm";

export const GET_ALL_FIRM_API_ENDPOINT = "/Firm/GetAll";

//expense
export const EXPENSE_API_ENDPOINT = "/Expense";

export const GET_ALL_EXPENSE_API_ENDPOINT = "/Expense/GetAll";

//transaction
export const TRANSACTION_API_ENDPOINT = "/Transaction";

export const GET_ALL_TRANSACTION_API_ENDPOINT = "/Transaction/GetAll";

export const TRANSACTION_REPORT_API_ENDPOINT = "/Transaction/Report";

//book
export const BOOK_API_ENDPOINT = "/Book";

export const GETALL_BOOK_API_ENDPOINT = "/Book/GetAll";

//bill
export const BILL_API_ENDPOINT = "/Bill";

export const GETALL_BILL_API_ENDPOINT = "/Bill/GetAll";

export const SAVE_PDF_OF_GENERATED_BILL_API_ENDPOINT = "/Bill/UploadPdf";

export const BILL_REPORT_API_ENDPOINT = "Bill/Report";

//proforma invoice
export const GET_ALL_PROFORMA_INVOCE_API_ENDPOINT = "/ProformaInvoice/GetAll";

export const PROFORMA_INVOCE_API_ENDPOINT = "/ProformaInvoice";

export const FINAL_PROFORMA_INVOCE_API_ENDPOINT = "/ProformaInvoice/Final";

//reimbursement invoice
export const GET_ALL_REIMBURSEMENT_INVOICE_API_ENDPOINT = "/ReimbursementInvoice/GetAll";

export const REIMBURSEMENT_INVOICE_API_ENDPOINT = "/ReimbursementInvoice";

//master data api
export const MASTER_DATA_API_ENDPOINT = "/MasterData/GetAll";

//activity
export const ACTIVITY_API_ENDPOINT = "/Activity";

export const GET_ACTIVITY_BY_ARTICLE_API_ENDPOINT = "/Activity/Assigned";

export const GET_ALL_ACTIVITY_API_ENDPOINT = "/Activity/GetAll";

//chat
export const GET_ALL_CHAT_MSG_API_ENDPOINT = "/Chat";

export const ADD_CHAT_MSG_API_ENDPOINT = "/Chat";
