import { connect } from "react-redux";
import { FC, useState } from "react";

import { adminHomePage } from "../main/admin/AdminMain";
import { absolutePath } from "../../../helpers/relativePath";
import { ADD_TASK, TASK_LIST } from "../../../helpers/routes";
import BillingForm from "../billing/BillingForm";
import { emptyObject } from "../../../helpers/helperFunction";
import { KTCard } from "../../../../_metronic/helpers";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { selectedState } from "../../../reducers/task/taskSelectionReducer";
import TaskFilter from "./component/TaskFilter";
import TaskGrouping from "./component/TaskGrouping";
import TaskTable from "./component/TaskTable";
import UsersListToolbar from "../../commonComponent/table/UsersListToolbar";
import UsersListSearchComponent from "../../commonComponent/table/UsersListSearchComponent";

export const taskListPage = {
  title: "Tasks",
  path: absolutePath(TASK_LIST),
  isSeparator: true,
  isActive: true,
};

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

interface props {
  selectedTaskDetails: selectedState;
}

const TaskList: FC<props> = ({ selectedTaskDetails }) => {

  const [bill, setBill] = useState({ show: false, edit: false, billId: null, isReimbursement: false });

  const closeModal = () => {
    setBill({ show: false, edit: false, billId: null, isReimbursement: false });
  };

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Tasks</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4 position-relative">
        {selectedTaskDetails.selected.length > 0 ? (
          <>
            <TaskGrouping
              selectedNumber={selectedTaskDetails.selected.length}
              setBill={setBill}
            />
          </>
        ) : (
          <>
            <UsersListSearchComponent />
            <TaskFilter />
            <UsersListToolbar buttons={[{ title: "Add Task", to: ADD_TASK }]} />
          </>
        )}
      </div>
      <KTCard>
        <TaskTable />
      </KTCard>
      <BillingForm bill={bill} closeModal={closeModal} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    selectedTaskDetails: state.selectedTask,
  };
};

export default connect(mapStateToProps)(TaskList);
